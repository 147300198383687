<template>
  <v-sheet 
    height="fit-content"
    min-height="100vh"
  >
    <v-toolbar flat height="fit-content">
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Oggi</v-btn>
      <v-btn fab text small color="grey darken-2" @click="prev">
        <v-icon small>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="next">
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon v-on="on" v-bind="attrs" class="ml-2">
            <v-icon>mdi-calendar-cursor</v-icon>
          </v-btn>
        </template>
        <v-date-picker v-model="localFocus" @input="handleDatePickerChange" first-day-of-week="1" locale="it"></v-date-picker>
      </v-menu>
      <slot name="toolbar-append" v-bind="{ 
        prev: prev,
        next: next
      }"></slot>

      <v-spacer></v-spacer>
      <v-scroll-y-transition>
        <v-progress-circular
          indeterminate
          color="grey"
          class="mr-4"
          width="2"
          v-if="localLoading"
        ></v-progress-circular>
      </v-scroll-y-transition>
      <OperatorsAutocomplete
        v-model="operatorIds"
        :return-object="false"
        @input="fetchEvents"
      >
      </OperatorsAutocomplete>
    </v-toolbar>
    <v-divider></v-divider>
    <div v-if="!!appointments && appointments.length > 0">
      <CollapsableList
        :items="appointments"
        item-key="id"
      >
        <template v-slot:item-title="{item}">
          <div class="d-flex align-center">
            <div style="width: 200px" class="font-weight-bold">
              {{item.customer.firstname}} {{item.customer.lastname}}
              <v-icon
                v-if="item.highPriority"
                class="mr-2 mb-1"
                small
                color="#9c0808"
              >mdi-alert</v-icon>
            </div>
            <div class="ml-10 d-flex align-center justify-center" style="width: 150px">
              Entra alle <v-chip class="ml-1">{{getEnterTime(item)}}</v-chip>
            </div>
            <div class="ml-10 d-flex align-center justify-center" style="width: 150px">
              Esce alle <v-chip class="ml-1">{{getExitTime(item)}}</v-chip>
            </div>
            <div class="d-flex align-center justify-center">
              <span class="mr-2">Creato da</span>
              <OperatorChip 
                v-if="!!item.cashDeskLog" 
                class="mt-1 mr-1"
                :value="item.cashDeskLog.operator"
              >
              </OperatorChip>
              <div v-else>
                <v-chip class="ml-2" color="grey lighten-2" text-color="grey darken-2">Cassa chiusa</v-chip>
              </div>
            </div>
            <div class="ml-10 d-flex align-center justify-center">
              <span class="mr-5">Operatori</span>
              <OperatorChip
                v-for="operator in getOperatorFromGroup(item)" 
                :key="operator.id" 
                class="mt-1 mr-1"
                :value="operator"
              ></OperatorChip>
            </div>
          </div>
        </template>
        <template v-slot:expansion="{item}">
          <div class="ml-10">
            <v-timeline
              align-center
              dense
            >
              <template v-for="event in item.events">
                <v-timeline-item
                  v-if="!!event.eventServices[0]"
                  :key="event.id"
                  :color="event.eventServices[0].service.color"
                  small
                  fill-dot
                >
                  <div class="d-flex align-center">
                    <div class="font-weight-bold text-caption">{{dateToTimeString(event.startDate)}}</div>
                    <div class="text-body-2 ml-10">
                      {{event.eventServices[0].service.name}}
                    </div>
                    <div class="text-body-2 ml-10">
                      <v-icon
                        v-if="event.eventServices[0].operatorsLocked"
                        class="px-1 pt-1"
                        small
                        color="#FF0000"
                      >mdi-heart</v-icon>
                    </div>
                    <div class="text-body-2 ml-10">
                      <OperatorChip
                        v-for="operator in event.eventServices[0].operators" 
                        :key="operator.id" 
                        class="mt-1 mr-1"
                        :value="operator"
                      ></OperatorChip>
                    </div>
                  </div>
                </v-timeline-item>
              </template>
            </v-timeline>
          </div>
        </template>
        <template v-slot:extra-icons="{item}">
          <v-btn 
            icon 
            class="ml-2" 
            @click.stop.prevent="viewEvent(item)"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
      </CollapsableList>
    </div>
    <div 
      v-else
      style="height: 100%"
      class="d-flex justify-center align-center"
    >
      <div class="font-weight-light text-caption">Nessun appuntamento per questo giorno</div>
    </div>
  </v-sheet>
</template>

<script>
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete.vue'
import OperatorChip from '@/components/common/OperatorChip.vue'
import { uniqBy } from "lodash";
import CollapsableList from '@/components/common/CollapsableList.vue'
import dateUtils from '@/mixins/common/dateUtils.js'
import Vue from "vue";

export default {
  name: "ListCalendar",
  mixins: [dateUtils],
  components: {
    OperatorsAutocomplete,
    CollapsableList,
    OperatorChip
  },
  data: function() {
    return {
      localLoading: false,
      operatorIds: [],
      localFocus: new Date(this.focus).toISOString().substring(0, 10),
      appointments: []
    }
  },
  props: {
    eventsFetcher: {
      type: Function,
      default: () => []
    },
    focus: {
      default: new Date()
    },
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  mounted: function() {
    this.fetchEvents()

    this.bus.$on("reload-events", () => {
      this.fetchEvents();
    });
  },
  methods: {
    prev() {
      this.localFocus = new Date(new Date(this.localFocus).getTime() - (24 * 60 * 60000)).toISOString().substring(0, 10)
      this.fetchEvents()
      this.$emit('update:focus', this.localFocus)
    },
    next() {
      this.localFocus = new Date(new Date(this.localFocus).getTime() + (24 * 60 * 60000)).toISOString().substring(0, 10)
      this.fetchEvents()
      this.$emit('update:focus', this.localFocus)
    },
    handleDatePickerChange() {
      this.$nextTick(() => {
        this.fetchEvents()
        this.$emit('update:focus', this.localFocus)
      })
    },
    setToday() {
      this.localFocus = new Date().toISOString().substring(0, 10)
      this.fetchEvents()
      this.$emit('update:focus', this.localFocus)
    },
    fetchEvents() {
      let beginningOfDay = new Date(this.localFocus);
      beginningOfDay.setHours(0);
      beginningOfDay.setMinutes(0);

      let endOfDay = new Date(this.localFocus);
      endOfDay.setHours(23);
      endOfDay.setMinutes(59);

      this.localLoading = true;
      this.eventsFetcher(beginningOfDay, endOfDay, { operatorIds: this.operatorIds }).then((events) => {
        this.appointments = events;
        this.localLoading = false;
      });
    },
    getEnterTime(group) {
      return this.dateToTimeString(group.events[0].startDate)
    },
    getExitTime(group) {
      return this.dateToTimeString(group.events[group.events.length - 1].endDate)
    },
    viewEvent(event) {
      this.$emit('show-event', event)
    },
    getOperatorFromGroup(group) {
      let operators = []
      for(let i = 0; i < group.events.length; i += 1) {
        for(let k = 0; k < group.events[i].eventServices.length; k += 1) {
          operators = [...operators, ...group.events[i].eventServices[k].operators]
        }
      }

      return uniqBy(operators, 'id')
    },
    getCreatorName(group) {
      let name = 'Sconosciuto'

      if(!!group.cashDeskLog && !!group.cashDeskLog.operator)
        name = group.cashDeskLog.operator.firstname + ' ' + group.cashDeskLog.operator.lastname

      return name
    },
  },
  computed: {
    title() {
      return this.dateToString('dd/mm/yyyy', new Date(this.localFocus))
    }
  },
  watch: {
    focus(newVal) {
      this.localFocus = new Date(newVal).toISOString().substring(0, 10)
      this.fetchEvents
    }
  }
}
</script>

<style>

</style>