
<template>
  <v-container fluid style="height: 100%; overflow-x: hidden">
    <v-row class="pa-0" style="height: 100%;">
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        style="height: auto; overflow-y: auto; overflow-x: hidden"
      >
        <v-row class="pa-1 ml-2" v-if="variant == 'new'">
          <v-col
            cols="12"
            sm="9"
            md="9"
            lg="9"
            xl="9"
            :style="{
                'padding-bottom': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm 
                  ? '0px' : '12px'
              }"
          >
            <DateTimePickerCircular 
              label="Inizio"
              :margin-top-time="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              v-model="localEventGroup.startDate" 
              @input="handleNewServiceDate"
            ></DateTimePickerCircular>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            :style="{
              'padding': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm  
                ? '0px' : '12px'
            }"
          >
            <v-checkbox
              v-model="localEventGroup.highPriority"
              @change="handlePriorityChange"
              label="Alta Priorità"
              class="ml-5"
            ></v-checkbox>
          </v-col>
          <!--<v-checkbox
            class="ml-5"
            v-model="splitInServicePhase"
            label="Dividi in fasi"
            hide-details="auto"
          ></v-checkbox>-->
        </v-row>
        <v-row>
          <v-col cols="auto">
            <CustomersAutocomplete
              v-if="canManage"
              v-model="customer"
              :loading="computedLoading"
              return-object
              :clearable="variant == 'new'"
              @input="handleCustomerChange"
              prepend-icon="mdi-information"
              :append-outer-icon="!!customer && canManage ? 'mdi-pencil' : undefined" 
              @click:prepend="toggleCustomerInformation"
              :focus-text="focusText"
              :disabled="fromBooking || disabled"
            ></CustomersAutocomplete>
            <v-text-field v-else :disabled="true" 
              :value="!!customer ? customer.lastname + ' ' + customer.firstname : ''"/>
          </v-col>
          <v-col v-if="variant == 'edit'" cols=3>
            <v-checkbox
              :disabled="!canManage || disabled"
              v-model="localEventGroup.highPriority"
              @change="handlePriorityChange"
            >
              <template slot="label">
                <span>Alta Priorità</span>
                <v-icon class="ml-1" color="#9c0808" :disabled="disabled">mdi-alert</v-icon>
              </template>
            </v-checkbox>
          </v-col>
          <!--<v-col v-if="variant == 'edit'" cols=3>
            <v-checkbox
              :disabled="!canManage"
              class="ml-1"
              v-model="splitInServicePhase"
              label="Dividi in fasi"
              hide-details="auto"
            ></v-checkbox>
          </v-col>-->
        </v-row>
        <v-row>
          <v-col
            style="display: flex; gap: 10px"
          >
            <v-text-field
              :disabled="!canManage || disabled"
              filled
              rounded
              style="min-width: 110px; max-width: 130px"
              label="Prefisso"
              dense
              prepend-icon="mdi-cellphone-basic"
              v-model="customerPrefixCellphone"
              v-debounce="updatePrefixCellphone"
            ></v-text-field>
            <v-text-field
              :disabled="!canManage || disabled"
              filled
              rounded
              style="min-width: 165px; max-width: 300px"
              label="Cellulare"
              dense
              v-model="customerPhoneNumber"
              v-debounce="updatePhoneNumber"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col class="pa-0">
            <v-expand-transition>
              <div class="mt-3 ml-3" v-if="customerInformationVisible && !!customer">
                <v-row class="d-flex">
                  <v-col>
                    <OperatorsAutocomplete
                      prepend-icon="mdi-palette"
                      v-model="customerPreferredColorOperatorId"
                      style="min-width: 200px; max-width: 300px"
                      :dense="true"
                      class="mt-1"
                      label="Operatore Preferito Colore"
                      v-debounce="updatePreferredColorOperator"
                      :disabled="disabled"
                    ></OperatorsAutocomplete>
                  </v-col>
                  <v-col>
                    <OperatorsAutocomplete
                      prepend-icon="mdi-content-cut"
                      v-model="customerPreferredHairCutOperatorId"
                      style="min-width: 200px; max-width: 300px"
                      :dense="true"
                      class="mt-1"
                      label="Operatore Preferito Taglio"
                      v-debounce="updatePreferredHairCutOperator"
                      :disabled="disabled"
                    ></OperatorsAutocomplete>
                  </v-col>
                  <v-col>
                    <OperatorsAutocomplete
                      prepend-icon="mdi-hair-dryer"
                      v-model="customerPreferredHairCurlerOperatorId"
                      style="min-width: 200px; max-width: 300px"
                      :dense="true"
                      class="mt-1"
                      label="Operatore Preferito Piega"
                      v-debounce="updatePreferredHairCurlerOperator"
                      :disabled="disabled"
                    ></OperatorsAutocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <EventServicesTimeline
              class="pr-5"
              v-model="selectedEventService"
              :items="eventServices"
              expand-on-click
              :dense="true"
              @delete="handleEventServiceDelete"
              :confirm-on-delete="false"
              :disabled="computedLoading || !canManage || (eventServices.length == 1 && variant == 'edit') || loadingDelete || fromBooking || disabled"
              :heart-disabled="disabled"
              @remove-operator="handleRemoveOperator"
              @move-service="handleMoveService"
              @lock="handleLockOperators"
              @unlock="handleUnlockOperators"
            ></EventServicesTimeline>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        style="height: 100%;"
      >
        <div v-if="canManage">
          <ServiceSelector 
            style="max-height: 400px"
            v-model="selectedService" 
            :disabled="serviceDisabled || fromBooking || disabled"
            @click="checkSelection"
            :mandatory="!serviceDisabled"
            :loading="computedLoading"
            :customer="customer"
            show-phase-on-hover
            show-frequent-services
          ></ServiceSelector>
          <OperatorSelector
            class="mt-4"
            v-model="selectedOperator" 
            return-object
            :show-only-name-operator="true"
            :disabled="operatorDisabled || fromBooking || disabled"
            @click="checkSelection"
            :mandatory="!operatorDisabled"
            :loading="computedLoading"
            :filters="filters"
          ></OperatorSelector>
        </div>
        <v-row>
          <v-col
            style="padding-bottom: 0px;"
          >
            <v-textarea
              v-model="localEventGroup.notes"
              color="primary"
              filled
              :disabled="computedLoading || !canManage || disabled"
              label="Note Appuntamento"
              v-debounce="userHasEndedTyped"
              class="mt-4"
            ></v-textarea>
          </v-col>
          <v-col
            style="padding-bottom: 0px;"
          >
            <v-textarea
              v-model="customerNotes"
              color="primary"
              :disabled="!canManage || disabled"
              :readonly="true"
              filled
              label="Note in anagrafica"
              class="mt-4"
            ></v-textarea>
          </v-col>    
        </v-row>  
      </v-col>
    </v-row>

    <SimplePopUp
      v-model="alertOverlay"
      :alert-text="alertText"
      :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80vw' :
          $vuetify.breakpoint.xl ? '20vw' : 
          $vuetify.breakpoint.lg ? '30vw' : '35vw'"
    >
      <template v-slot:content>
        <TypeDataTable
          headers-color="transparent"
          selected-color="transparent"
          style="background-color: transparent"
          :headers="alertHeaders"
          :items="alertServices"
          :show-actions="false"
          :show-select="false"
          :disable-pagination="true"
        ></TypeDataTable>
      </template>
    </SimplePopUp>
  </v-container>
</template>

<script>
import _ from 'lodash'
import OperatorSelector from '@/components/common/OperatorSelector.vue'
import ServiceSelector from '@/components/common/ServiceSelector.vue'
import CustomersAutocomplete from '@/components/common/CustomersAutocomplete.vue'
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete.vue'
import EventServicesTimeline from '@/components/calendar/EventServicesTimeline.vue'
import eventServiceForm from "@/services/calendar/eventService.form.js"
import customersService from '@/services/customers/customers.service.js'
import paymentTransactionsService from '@/services/paymentTransactions/paymentTransactions.service.js'
import debounce from "@/mixins/common/debounce.js"
import DateTimePickerCircular from '@/components/common/DateTimePickerCircular.vue';
import SimplePopUp from '@/components/common/SimplePopUp.vue'
import TypeDataTable from "@/components/common/TypeDataTable.vue";

export default {
  name: "EventGroupForm",
  components: {
    OperatorSelector,
    ServiceSelector,
    CustomersAutocomplete,
    EventServicesTimeline,
    OperatorsAutocomplete,
    DateTimePickerCircular,
    SimplePopUp,
    TypeDataTable
  },
  mixins: [debounce],
  data: function() {
    return {
      localEventGroup: {},
      selectedOperator: undefined,
      selectedService: undefined,
      selectedEventService: undefined,
      customer: !!this.value && !!this.value.customer ? this.value.customer : undefined,
      eventServices: !!this.value && !!this.value.eventServices ? this.value.eventServices : [],
      localLoading: false,
      customerInformationVisible: true,
      customerPrefixCellphone: undefined,
      customerPhoneNumber: undefined,
      customerPreferredColorOperatorId: [],
      customerPreferredHairCutOperatorId: [],
      customerPreferredHairCurlerOperatorId: [],
      customerNotes: undefined,
      loadingDelete: false,
      serviceTypesToCheck: [
        { name: 'TAGLIO UOMO', expireDays: 40 },
        { name: 'TAGLIO CAPELLI DONNA', expireDays: 40 },
        { name: 'COLORE DONNA', expireDays: 30 },
        { name: 'SCHIARITURA DONNA MANO LIBERA', expireDays: 90 },
        { name: 'SCHIARITURA DONNA IN CARTINA', expireDays: 90 },
        { name: 'SUPPLEMENTO SCHIARITURE', expireDays: 90 },
        { name: 'TONALIZZANTI - RIFLESSANTI', expireDays: 30 }
      ],
      alertOverlay: false,
      alertText: "",
      alertServices: [],
      alertHeaders: [
        { text: 'Servizio', value: 'serviceTypeName' },
        { text: 'Giorni', value: 'expireDays'},
      ],
    }
  },
  props: {
    focusText: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object,
      default: function() {
        return {
          color: {},
          customer: {},
          eventServices: [ ],
          startDate: undefined,
        }
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
    },
    canManage: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({
        notInStateOperator: 'disabled',
        calendar: {
          name: "Parrucchiere"
        }
      })
    },
    variant: {
      type: String,
      default: 'new',
      validator: function(value) {
        if(!['new', 'edit'].includes(value)) {
          return 'invalid variant'
        } else {
          return true
        }
      } 
    },
    modCalendar:{
      type: String,
      default: 'PRO'
    },
    defaultOperator: {
      type: Object,
      default: undefined
    },
    splitInServicePhase: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted: async function() {
    this.localEventGroup = eventServiceForm.eventGroup
    this.customer = eventServiceForm.eventGroup.customer
    this.eventServices = eventServiceForm.eventServices
    if(!!this.customer) {
      await customersService.get(this.customer.id).then(customer => {
        this.customer = customer
      })
      this.customerPhoneNumber = this.customer.cellphone
      this.customerPrefixCellphone = this.customer.prefixCellphone
      this.customerPreferredColorOperatorId = this.customer.preferredColorOperatorId
      this.customerPreferredHairCutOperatorId = this.customer.preferredHairCutOperatorId
      this.customerPreferredHairCurlerOperatorId = this.customer.preferredHairCurlerOperatorId
      this.customerNotes = this.customer.noteInterests
    } else {
      this.customerPhoneNumber = undefined
    }

    let eventServicesHandler = ({eventServices}) => {
      this.eventServices = eventServices
      this.$emit('input', this.eventGroup)
    }
    eventServicesHandler = eventServicesHandler.bind(this)
    eventServiceForm.on("change-event-services", eventServicesHandler)
    eventServiceForm.on("disable-delete", (val) => this.loadingDelete = val)

    let eventGroupHandler = ({ group }) => {
      this.customer = group.customer
      this.localEventGroup = group
      this.$emit('input', this.eventGroup)
    }
    eventGroupHandler = eventGroupHandler.bind(this)
    eventServiceForm.on("change-event-services", eventGroupHandler)
  },
  methods: {
    checkSelection() {
      this.$nextTick(function() {
        if(!!this.selectedService) {
          this.addPartialRow(this.selectedService)
        } else if(!!this.selectedOperator && !!this.selectedEventService) {
          this.completeRow(this.selectedEventService, this.selectedOperator)
        } else if(!!this.selectedOperator && !this.selectedEventService) {
          this.setTheFirstWithNoOperators(this.selectedOperator)
        }

        this.selectedOperator = undefined
        this.selectedService = undefined
      })
    },
    userHasEndedTyped() {
      eventServiceForm.update({notes: this.localEventGroup.notes})
    },
    addRow(service, operator) {
      if(this.splitInServicePhase && !!service.servicePhases && service.servicePhases.length != 0) {
        for(let i = 0; i < service.servicePhases.length; i += 1) {
          eventServiceForm.addService(service, operator, service.servicePhases[i])
        }
      } else {
        eventServiceForm.addService(service, operator)
      }
    },
    async addPartialRow(service) {
      if(this.splitInServicePhase && !!service.servicePhases && service.servicePhases.length != 0) {
        for(let i = 0; i < service.servicePhases.length; i += 1) {
          await eventServiceForm.addService(service, [], service.servicePhases[i])
        }
      } else {
        await eventServiceForm.addService(service)
      }

      if (this.eventServices.length == 1 && !!this.defaultOperator) {
        await eventServiceForm.addOperatorToService(this.eventServices[0], this.defaultOperator)
      }
    },
    completeRow(eventService, operator) {
      eventServiceForm.addOperatorToService(eventService, operator)
    },
    setTheFirstWithNoOperators(operator) {
      for(let i = 0; i < this.eventServices.length; i++) {
        if(this.eventServices[i].operators.length == 0) {
          this.completeRow(this.eventServices[i], operator)
          break
        }
      }
    },
    handleRemoveOperator(operator, eventService) {
      eventServiceForm.removeOperatorFromService(eventService, operator).catch((err) => {
        console.log(err)
        this.$delegates.snackbar("Impossibile rimuovere operatore")
      })
    },
    handleEventServiceDelete(eventService) {
      eventServiceForm.removeService(eventService, this.modCalendar)
    },
    handleMoveService(eventService, newIndex, newEventServices) {
      eventServiceForm.moveInPosition(eventService, newIndex).catch((error) => {
        this.eventServices = eventServiceForm.eventServices
        this.$delegates.snackbar("Impossibile spostare event non consequenziali")
      })
    },
    handlePriorityChange(newPriority) {
      eventServiceForm.update({highPriority: newPriority})
    },
    handleNewServiceDate(newDate) {
      if(eventServiceForm.mode == 'create') {
        eventServiceForm.update({startDate: newDate})
      }
    },
    handleCustomerChange(newCustomer) {
      eventServiceForm.setCustomer(newCustomer)
      if(!!newCustomer) {
        this.customerPhoneNumber = newCustomer.cellphone
        this.customerPrefixCellphone = newCustomer.prefixCellphone
        this.customerPreferredColorOperatorId = newCustomer.preferredColorOperatorId
        this.customerPreferredHairCutOperatorId = newCustomer.preferredHairCutOperatorId
        this.customerPreferredHairCurlerOperatorId = newCustomer.preferredHairCurlerOperatorId
        this.customerNotes = newCustomer.noteInterests

        paymentTransactionsService.lastCustomerTransactionByServiceTypes({
          customerId: newCustomer.id,
          serviceTypes: this.serviceTypesToCheck
        }).then((results) => {
          if (!!results && results.length > 0) {
            this.alertOverlay = true
            this.alertServices = results
            let initialText = 'Il cliente ' + newCustomer.firstname + ' ' + newCustomer.lastname + ' non effettua i seguenti servizi da:\n\n'
            //initialText += results.map(el => el.serviceTypeName + ': passati più di ' + el.expireDays + ' giorni').join('\n') 
            this.alertText = initialText
          }
        })
      }
      else {
        this.customerNotes = undefined
        this.customerPhoneNumber = undefined
      }
    },
    handleLockOperators(eventService) {
      eventServiceForm.lockOperators(eventService)
    },
    handleUnlockOperators(eventService) {
      eventServiceForm.unlockOperators(eventService)
    },
    toggleCustomerInformation() {
      this.customerInformationVisible = !this.customerInformationVisible
    },
    updatePreferredColorOperator() {
      customersService.update({
        id: this.customer.id,
        preferredColorOperatorId: this.customerPreferredColorOperatorId,
      }, false)
    },
    updatePreferredHairCutOperator() {
      customersService.update({
        id: this.customer.id,
        preferredHairCutOperatorId: this.customerPreferredHairCutOperatorId,
      }, false)
    },
    updatePreferredHairCurlerOperator() {
      customersService.update({
        id: this.customer.id,
        preferredHairCurlerOperatorId: this.customerPreferredHairCurlerOperatorId,
      }, false)
    },
    updatePhoneNumber() {
      customersService.update({
        id: this.customer.id,
        cellphone: this.customerPhoneNumber
      }, false)
    },
    updatePrefixCellphone() {
      customersService.update({
        id: this.customer.id,
        prefixCellphone: this.customerPrefixCellphone
      }, false)
    },
  },
  watch: {
    localValid(newVal) {
      this.$emit('update:valid', newVal)
    }
  },
  computed: {
    computedLoading() {
      return this.loading && this.localLoading
    },
    customerValid() {
      return !!this.customer
    },
    serviceDisabled() {
      return !!this.selectedEventService
    },
    operatorDisabled() {
      return false
    },
    operatorsValid() {
      return this.eventServices.every(el => !!el.operators && el.operators.length > 0)
    },
    localValid() {
      return this.eventServices.length > 0 && this.operatorsValid && this.customerValid
    },
    eventId() {
      return !!this.localEventGroup ? this.localEventGroup.id : undefined
    },
    eventEndDate() {
      return !!this.localEventGroup ? this.localEventGroup.endDate : undefined
    },
    eventGroup() {
      return {
        eventServices: this.eventServices,
        customer: this.customer,
        startDate: this.localEventGroup.startDate,
        highPriority: this.localEventGroup.highPriority,
        notes: this.localEventGroup.notes,
        star: this.localEventGroup.star,
        fromBooking: this.localEventGroup.fromBooking,
        eventGroups: this.value.eventGroups,
      }
    },
    fromBooking(){
      if(!this.value || !this.value.eventGroups || this.value.eventGroups.length == 0) return false
      return !!this.value.eventGroups[0].fromBooking
    }
  }
}
</script>

<style>

</style>