<template>
  <v-row>
    <v-col style="overflow-y: auto" class="pr-5">
      <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div v-else-if="localItems.length == 0 && !hideNoData" class="font-weight-thin text-center">
        {{noDataMessage}}
      </div>
      <v-timeline align-top dense v-else-if="localItems.length != 0">
        <draggable v-model="localItems" handle=".draggable-area" @end="endTouch" ghost-class="ghost" ref="draggableComponent">
          <v-slide-x-reverse-transition
            group
            hide-on-leave
          >
            <v-timeline-item
              fill-dot
              small
              v-for="eventService in localItems"
              :key="eventService[eventServiceKey]"
              :class="{
                'pa-0': dense
              }"
            >
              <v-card
                elevation="5"
                :class="{
                  'editing': editEventService && eventService[eventServiceKey] == editEventService[eventServiceKey]
                }"
                @click="expandEventService(eventService)"
              >
                <v-card-text class="white text--primary py-2">
                  <v-row class="pa-1" justify="center" align="center">
                    <v-col 
                      :class="{
                        'draggable-area': !disabled
                      }"
                      class="px-1" 
                      cols="auto"
                    >
                      <v-icon>mdi-arrow-all</v-icon>
                    </v-col>
                    <v-col class="pr-2">
                      <span class="text-h6">
                        {{eventService.service.name}} <span v-if="!!eventService.phase">({{eventService.phase.name}})</span>
                      </span>
                      <div class="text-body">
                        <template v-if="showDuration && (!expandOnClick || (expandOnClick && !!expandedEventService && expandedEventService[eventServiceKey] == eventService[eventServiceKey]))">
                          <span class="font-weight-thin">Durata servizio:</span> 
                          <span v-if="!eventService.phase">
                            {{humanReadableMinutes(eventService.service.minutes)}}
                          </span>
                          <span v-else>
                            {{humanReadableMinutes(eventService.phase.minutes)}}
                          </span>
                        </template>
                        <div v-if="!!eventService[eventStartDate] && showStartDate && (!expandOnClick || (expandOnClick && !!expandedEventService && expandedEventService[eventServiceKey] == eventService[eventServiceKey]))">
                          <span class="font-weight-thin">Inizio alle:</span>
                          {{dateToString('dd/mm/yyyy', new Date(eventService[eventStartDate]))}}, {{dateToTimeString(new Date(eventService[eventStartDate]))}}
                        </div>
                        <div v-if="!!eventService[eventEndDate] && showEndDate && (!expandOnClick || (expandOnClick && !!expandedEventService && expandedEventService[eventServiceKey] == eventService[eventServiceKey]))">
                          <span class="font-weight-thin">Fine alle:</span>
                          {{dateToString('dd/mm/yyyy', new Date(eventService[eventEndDate]))}}, {{dateToTimeString(new Date(eventService[eventEndDate]))}}
                        </div>
                      </div>
                    </v-col>
                    <v-col>
                      <div class="d-flex flex-wrap" v-if="eventService[operatorsKey]">
                        <OperatorChip 
                          v-for="operator in eventService.operators" 
                          :key="operator.id" 
                          class="mt-1 mr-1"
                          :show-only-name-operator="true"
                          :value="operator"
                          :close="!eventService.operatorsLocked"
                          @close="handleRemoveOperator(operator, eventService)"
                          :disabled="disabled"
                        ></OperatorChip>
                      </div>
                    </v-col>
                    <div>
                      <template v-if="!editEventService || eventService[eventServiceKey] != editEventService[eventServiceKey]">
                        <v-btn
                          icon
                          @click.stop.prevent="handleToggleLock(eventService)"
                          :disabled="heartDisabled"
                        >
                          <v-icon v-if="eventService.operatorsLocked" color="#FF0000">mdi-heart</v-icon>
                          <v-icon v-else>mdi-heart-outline</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          class="ml-2"
                          @click.stop.prevent="handleDelete(eventService)"
                          :disabled="disabled"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <v-btn 
                          icon
                          class="ml-2"
                          @click.stop.prevent="handleEdit(eventService)"
                          :disabled="disabled || eventService.operatorsLocked"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <v-btn 
                        icon
                        @click.stop.prevent="handleCancelEdit"
                        v-else
                        :disabled="disabled"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-slide-x-reverse-transition>
        </draggable>
      </v-timeline>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable'
import dateUtils from '@/mixins/common/dateUtils'
import OperatorChip from '@/components/common/OperatorChip.vue'

export default {
  name: "EventServicesTimeline",
  components: {
    OperatorChip,
    draggable
  },
  mixins: [
    dateUtils
  ],
  data: function() {
    return {
      editEventService: !!this.value ? {...this.value} : undefined,
      localItems: [...this.items],
      expandedEventService: undefined
    }
  },
  props: {
    value: {},
    items: {
      type: Array,
      default: () => []
    },
    eventServiceKey: {
      type: String,
      default: 'id'
    },
    eventStartDate: {
      type: String,
      default: 'startDate'
    },
    eventEndDate: {
      type: String,
      default: 'endDate'
    },
    operatorsKey: {
      type: String,
      default: 'operators'
    },
    showStartDate: {
      type: Boolean,
      default: true,
    },
    showEndDate: {
      type: Boolean,
      default: true,
    },
    showDuration: {
      type: Boolean,
      default: true
    },
    confirmOnDelete: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    noDataMessage: {
      type: String,
      default: 'Nessun Servizio'
    },
    hideNoData: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    heartDisabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    expandOnClick: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleDelete(eventService) {
      let confirmed;
      if(this.confirmOnDelete) {
        confirmed = confirm('sei sicuro di voler eliminare il gruppo?')
      } else {
        confirmed = true
      }

      if(confirmed) {
        this.$emit('delete', eventService)
      }
    },
    handleEdit(eventService) {
      this.editEventService = eventService
      this.$emit('input', this.editEventService)
    },
    handleCancelEdit() {
      this.editEventService = undefined
      this.$emit('input', this.editEventService)
    },
    handleRemoveOperator(operator, eventService) {
      this.$emit('remove-operator', operator, eventService)
    },
    handleToggleLock(eventService) {
      if(eventService.operatorsLocked)
        this.$emit('unlock', eventService)
      else
        this.$emit('lock', eventService)
    },
    expandEventService(eventService) {
      if(this.expandOnClick) {
        if(!!this.expandedEventService && this.expandedEventService[this.eventServiceKey] == eventService[this.eventServiceKey]) {
          this.expandedEventService = undefined
        } else {
          this.expandedEventService = eventService
        }
      }
    },
    endTouch(event) {
      if(event.newIndex == event.oldIndex) return
      this.$emit('move-service', this.localItems[event.newIndex], event.newIndex, this.localItems)
    }
  },
  watch: {
    value(newVal) {
      this.editEventService = !!newVal ? {...newVal} : undefined
    },
    items(newVal) {
      this.localItems = [...newVal]
    }
  }
}
</script>

<style scoped>
.editing {
  width: 95%;
}

.animation-properties {
  transition: all 0.2s;
}

.draggable-area {
	cursor: move
}

.ghost {
	/* background-color: #b6b6b6; */
	opacity: 0.2;
}
</style>