<template>
  <div>
    <CategoriesCalendar
      v-model="focus"
      :external-focus="calendarDateFromVoice"
      class="categories-calendar"
      :category-width="100"
      :select-max-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm
        ? '40dvw' : '500px'"
      category-label="Operatori"
      category-placeholder="Tutti gli operatori"
      category-text-key="firstname"
      category-value-key="id"
      event-end="endDate"
      event-start="startDate"
      :unavailabilities-fetcher="unavailabilitiesFetcher"
      :round-to="calendarRoundToSetting"
      :event-color="colorFetcher"
      :event-name="nameFetcher"
      :event-detail="detailFetcher"
      :bus="bus"
      scroll-to-time="07:00"
      :interval-height-default="13.5"
      :event-category="categoryFetcher"
      :event-category-setter="categorySetter"
      :event-timed="(event) => { return !event.allDay }"
      :categories-fetcher="selectionCalendarOperator ? fetchOperators : fetchOperatorsAlt"
      :move-between-category="(event) => { return !(isLicenseBlocked ||isOnlyWheelOfFortune) && !event.custom && !event.eventServices[0].operatorsLocked }"
      :move-in-time="(event) => { return !(isLicenseBlocked ||isOnlyWheelOfFortune) && ((modCalendar == 'PRO' && iconLock === 'mdi-lock-open') || modCalendar == 'DYNAMIC') && (!!event.custom || !event.eventServices[0].operatorsLocked) && (!event.eventGroups[0].fromBooking) }"      
      :events-fetcher="fetchEventsForCategories"
      :can-manage="canManageCalendar"
      v-on:new-event="wrapperNewEventGroup"
      v-on:show-event="handleCalendarShow"
      v-on:end-drag="handleEndDrag"
      v-if="currentView == 'operatorsView'"
      id="calendar"
    >
      <template v-slot:toolbar-append>
        <StandardMenu v-model="viewMenu" :showTooltip="true">
          <template v-slot:activator="{attrs: attrsMenu, on: onMenu}">
            <v-tooltip bottom>
              <template v-slot:activator="{attrs: attrsTooltip, on: onTooltip}">
                <v-btn v-on="{...onMenu, ...onTooltip}" v-bind="{...attrsMenu, ...attrsTooltip}" icon class="ml-1" :disabled="isLicenseBlocked || isOnlyWheelOfFortune">
                  <v-icon>mdi-calendar-blank-multiple</v-icon>
                </v-btn>
              </template>
              <span> Seleziona Vista </span>
            </v-tooltip>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item-group color="primary" v-model="currentViewIndex" mandatory>
                <v-list-item v-for="view in views" :key="view.name" @click="viewMenu = false">
                  <v-list-item-icon class="mr-3">
                    <v-icon>{{view.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{view.text}}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </StandardMenu>
        <StandardMenu v-model="viewAppointmentMenu">
          <template v-slot:activator="{attrs: attrsMenu, on: onMenu}">
            <v-tooltip bottom>
              <template v-slot:activator="{attrs: attrsTooltip, on: onTooltip}">
                <v-btn v-on="{...onMenu, ...onTooltip}" v-bind="{...attrsMenu, ...attrsTooltip}" icon class="ml-1" :disabled="isLicenseBlocked || isOnlyWheelOfFortune">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span> Nuovo Evento </span>
            </v-tooltip>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item-group color="primary" v-model="currentViewAppointmentIndex" mandatory>
                <v-list-item v-for="view in newAppointmentViews" :key="view.name" @click="openNewAppointment(view)">
                  <v-list-item-icon class="mr-3">
                    <v-icon>{{view.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{view.text}}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </StandardMenu>
        <v-tooltip bottom v-if="$vuetify.breakpoint.lgAndUp">
          <template v-slot:activator="{attrs, on}">
            <v-btn icon class="ml-1" @click="openSearchForCustomerDialog" v-on="on" v-bind="attrs">
              <v-icon>mdi-account-search</v-icon>
            </v-btn>
          </template>
          <span> Cerca Cliente </span>
        </v-tooltip>
        <v-tooltip bottom v-if="$vuetify.breakpoint.lgAndUp">
          <template v-slot:activator="{attrs, on}">
            <v-btn icon class="ml-1" @click="openPrevisionalDialog" v-on="on" v-bind="attrs">
              <v-icon>mdi-chart-bar</v-icon>
            </v-btn>
          </template>
          <span> Statistiche </span>
        </v-tooltip>
        <v-tooltip bottom v-if="$vuetify.breakpoint.lgAndUp">
          <template v-slot:activator="{attrs, on}">
            <v-btn 
              icon 
              class="ml-1"
              :loading="printLoading"
              v-bind="attrs"
              v-on="on"
              @click="printAppointments"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
          <span> Stampa Appuntamenti </span>
        </v-tooltip>
        <StandardMenu close-on-content-click v-model="selectedCalendarOperator" :showTooltip="true">
          <template v-slot:activator="{attrs: attrsMenu, on: onMenu}">
            <v-tooltip bottom>
              <template v-slot:activator="{attrs: attrsTooltip, on: onTooltip}">
                <v-btn v-on="{...onMenu, ...onTooltip}" v-bind="{...attrsMenu, ...attrsTooltip}" icon class="ml-1">
                  <v-icon>mdi-account-group</v-icon>
                </v-btn>
              </template>
              <span> Seleziona Categoria </span>
            </v-tooltip>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item-group multiple mandatory color="primary" v-model="selectedCalendarOperatorIndex">
                <v-list-item v-for="calendar in calendarsOperator" :key="calendar.name" @click="selectedCalendar">
                  <v-list-item-icon class="mr-3">
                    <v-icon>{{calendar.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{calendar.text}}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </StandardMenu>
        <StandardMenu close-on-content-click v-model="selectedMessageMenu" :showTooltip="true">
          <template v-slot:activator="{attrs: attrsMenu, on: onMenu}">
            <v-tooltip bottom>
              <template v-slot:activator="{attrs: attrsTooltip, on: onTooltip}">
                <v-btn v-on="{...onMenu, ...onTooltip}" v-bind="{...attrsMenu, ...attrsTooltip}" icon class="ml-1" :disabled="isLicenseBlocked || isOnlyWheelOfFortune">
                  <v-icon>mdi-message-text-fast</v-icon>
                </v-btn>
              </template>
              <span> Messaggi </span>
            </v-tooltip>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item-group color="primary" v-model="selectedMessageMenuIndex" mandatory>
                <v-list-item v-for="message in messageOptions" :key="message.name" @click="selectedMessageOptions(message.name)">
                  <v-list-item-icon class="mr-3">
                    <v-icon>{{message.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{message.text}}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </StandardMenu>
        <v-tooltip bottom v-if="$vuetify.breakpoint.lgAndUp">
          <template v-slot:activator="{attrs, on}">
            <v-btn icon class="ml-1" @click="openSettings" v-on="on" v-bind="attrs">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <span> Impostazioni </span>
        </v-tooltip>
        <v-tooltip bottom v-if="$vuetify.breakpoint.lgAndUp">
          <template v-slot:activator="{attrs, on}">
            <v-btn icon class="ml-1" @click="openOrderOperator" v-on="on" v-bind="attrs">
              <v-icon>mdi-order-numeric-ascending</v-icon>
            </v-btn>
          </template>
          <span> Ordine Operatori </span>
        </v-tooltip>
        <v-tooltip bottom v-if="viewBooking.active">
          <template v-slot:activator="{attrs, on}">
            <v-btn icon class="ml-1" @click="() => carpeDiemDialog = true" v-on="on" v-bind="attrs" :disabled="isLicenseBlocked || isOnlyWheelOfFortune">
              <v-icon>mdi-diamond-stone</v-icon>
            </v-btn>
          </template>
          <span> Carpe Diem </span>
        </v-tooltip>
        <v-tooltip bottom v-if="modCalendar == 'PRO'">
          <template v-slot:activator="{attrs, on}">
            <v-btn icon class="ml-1" @click="changeLock" v-on="on" v-bind="attrs" :color="iconLock == 'mdi-lock' ? 'red' : 'green'">
              <v-icon>{{iconLock}}</v-icon>
            </v-btn>
          </template>
          <span> {{iconLock == 'mdi-lock' ? 'Attiva lo spostamento verticale' : 'Disattiva lo spostamento verticale'}} </span>
        </v-tooltip>
        <StandardMenu v-if="!$vuetify.breakpoint.lgAndUp">
          <template v-slot:activator="{attrs: attrsMenu, on: onMenu}">
            <v-tooltip bottom>
              <template v-slot:activator="{attrs: attrsTooltip, on: onTooltip}">
                <v-btn v-on="{...onMenu, ...onTooltip}" v-bind="{...attrsMenu, ...attrsTooltip}" icon class="ml-1">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <span> Altre opzioni </span>
            </v-tooltip>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item @click="openSearchForCustomerDialog">
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-account-search</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Cerca cliente</v-list-item-content>
              </v-list-item>
              <v-list-item @click="openPrevisionalDialog">
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-chart-bar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Statistiche</v-list-item-content>
              </v-list-item>
              <v-list-item @click="printAppointments">
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Stampa</v-list-item-content>
              </v-list-item>
              <v-list-item @click="openSettings">
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Impostazioni</v-list-item-content>
              </v-list-item>
              <v-list-item @click="openOrderOperator">
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-order-numeric-ascending</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Ordine operatori</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </StandardMenu>
      </template>
      <template v-slot:event-actions="{event}">
        <div class="py-1">
          <v-icon
            v-if="!!event.eventGroups[0] && !!event.eventGroups[0].carpeDiem"
            x-small
            color="#50BFE1"
          >mdi-diamond-stone</v-icon>
          <v-icon
            v-if="!!event.eventGroups[0] && !!event.eventGroups[0].fromBooking"
            x-small
            color="#FF8C00"
          >mdi-map-marker</v-icon>
          <v-icon
            v-if="!!event.eventGroups[0] && event.eventGroups[0].highPriority"
            x-small
            color="#9C0808"
          >mdi-alert</v-icon>
          <v-icon
            v-if="!!event.eventGroups[0] && event.eventGroups[0].star"
            x-small
            color="#EBFF00"
          >mdi-star</v-icon>
          <v-icon
            v-if="!event.custom && event.eventServices[0].operatorsLocked"
            x-small
            color="#FF0000"
          >mdi-heart</v-icon>
          <v-icon
            v-if="!!event.eventGroups[0] && !!event.eventGroups[0].notes"
            x-small
            color="blue"
          >mdi-information</v-icon>
        </div>
      </template>
    </CategoriesCalendar>

    <WeekCalendar
      :focus.sync="focus"
      style="height: 100vh"
      event-end="endDate"
      event-start="startDate"
      :event-color="colorFetcher"
      :event-name="nameFetcher"
      :bus="bus"
      :event-timed="(event) => { return !event.allDay}"
      :events-fetcher="fetchEventsForWeek"
      v-on:new-event="wrapperNewEventGroup"
      v-on:show-event="handleShowEventGroup"
      v-on:end-drag="handleEndDrag"
      v-else-if="currentView == 'weekView'"
    >
      <template v-slot:toolbar-append>
        <StandardMenu v-model="viewMenu">
          <template v-slot:activator="{attrs, on}">
            <v-btn v-on="on" v-bind="attrs" icon class="ml-1">
              <v-icon>mdi-calendar-blank-multiple</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item-group color="primary" v-model="currentViewIndex" mandatory>
                <v-list-item v-for="view in views" :key="view.name" @click="viewMenu = false">
                  <v-list-item-icon class="mr-3">
                    <v-icon>{{view.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{view.text}}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </StandardMenu>
        <v-btn icon class="ml-1">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </WeekCalendar>

    <ListCalendar
      v-if="currentView == 'listView'"
      :events-fetcher="fetchEventsForList"
      v-on:new-event="wrapperNewEventGroup"
      @show-event="handleListCalendarShow"
      :focus.sync="focus"
      :bus="bus"
    >
      <template v-slot:toolbar-append>
        <StandardMenu v-model="viewMenu">
          <template v-slot:activator="{attrs, on}">
            <v-btn v-on="on" v-bind="attrs" icon class="ml-1">
              <v-icon>mdi-calendar-blank-multiple</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item-group color="primary" v-model="currentViewIndex" mandatory>
                <v-list-item v-for="view in views" :key="view.name" @click="viewMenu = false">
                  <v-list-item-icon class="mr-3">
                    <v-icon>{{view.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{view.text}}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </StandardMenu>
      </template>
    </ListCalendar>

    <StandardDialog
      v-model="closinghours"
      title="Attenzione"
      :dialog-height="null"
      dialog-width="350px"
      persistent
    >
      <div class="d-flex justify-center mt-2">
        <v-alert 
          class="ma-2" 
          dense 
          outlined 
          type="error" 
          style="width: 250px"
        >
          {{closingHoursMessage}}
        </v-alert>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="closeAlertDialog">torna indietro</v-btn>
        <v-btn
          @click="passAlertDialog"
          text
          color="default"
        >OK</v-btn>
      </template>
    </StandardDialog>

    <!-- Nuovo appuntamento -->
    <FullScreenDialog
      v-model="newEventGroupForm"
      title="Nuovo Appuntamento"
      without-padding
      persistent
      :canClose="false"
    >
      <template v-slot:header-actions>
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon
              class="ml-2"
              v-on="on"
              v-bind="attrs"
              @click="openSendSmsDialog('newAppointment')"
              :disabled="!canManageCalendar || !newEventGroupFormValid"
            >
              <v-icon>mdi-message-text</v-icon>
            </v-btn>
          </template>
          Invia un sms al cliente
        </v-tooltip>
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon
              class="ml-2"
              v-on="on"
              v-bind="attrs"
              @click="deleteAllServices"
            >
              <v-icon>mdi-close-box-multiple-outline</v-icon>
            </v-btn>
          </template>
          Rimuovi tutti i servizi dell'evento
        </v-tooltip>
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon 
              class="ml-2"
              v-on="on"
              v-bind="attrs"
              :disabled="!showCustomer"
              @click="showEventCustomerAnalytics = true"
            >
              <v-icon>mdi-chart-areaspline</v-icon>
            </v-btn>
          </template>
          Statistiche Cliente
        </v-tooltip>
      </template>
      <template v-slot:content >
        <div style="height: 100%">
          <EventGroupForm
            ref="newEventGroupRef"
            v-model="newEventGroup"
            class="py-2"
            :loading="loadingForm"
            :bus="eventFormBus"
            variant="new"
            :valid.sync="newEventGroupFormValid"
            :can-manage="canManageCalendar"
            :filters="operatorFilter"
            :modCalendar="modCalendar"
            :default-operator="selectedColumnOperator"
            :focus-text="true"
            :split-in-service-phase="splitInServicePhase"
          ></EventGroupForm>
        </div>
      </template>
      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="handleCancelEventGroupForm">Annulla</v-btn>
        <v-btn
          text
          color="default"
          @click="wrapperNewEventGroup"
          :loading="loadingForm"
          :disabled="!newEventGroupFormValid"
        >Salva</v-btn>
      </template>
    </FullScreenDialog>

    <!-- Modifica appuntamento -->
    <FullScreenDialog
      v-model="editEventGroupForm"
      title="Modifica Appuntamento"
      without-padding
      persistent
      :canClose="false"
    >
      <template v-slot:header-actions>
        <StandardDialog
          v-model="duplicateDataForm"
          title="Duplica Evento - Seleziona una data"
          dialog-width="700px"
          :dialog-height="null"
        >
          <template v-slot:footer>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="duplicateDataForm = false">Chiudi</v-btn>
            <v-btn text color="default" @click="createDuplicate">Conferma</v-btn>
          </template>
          <DuplicateDateForm
            :duplicate-date.sync="duplicateDate"
            :end-date.sync="duplicateEndDate"
            :start-date.sync="duplicateStartDate"
            :rule-type.sync="duplicateRuleType"
            :days-of-week.sync="duplicateDaysOfWeeks"
            :days-of-month.sync="duplicateDaysOfMonth"
          ></DuplicateDateForm>
        </StandardDialog>

        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon
              class="ml-2"
              v-on="on"
              v-bind="attrs"
              @click="deleteEventGroup()"
              :disabled="!canManageCalendar"
              color="red"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          Cancella Appuntamento
        </v-tooltip>
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon
              class="ml-2"
              v-on="on"
              v-bind="attrs"
              @click="openSendSmsDialog('editAppointment')"
              :disabled="!canManageCalendar || isLicenseBlocked || isOnlyWheelOfFortune"
            >
              <v-icon>mdi-message-text</v-icon>
            </v-btn>
          </template>
          Invia un sms al cliente
        </v-tooltip>
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon 
              class="ml-2" 
              v-on="on"
              v-bind="attrs"
              @click="editNewDateService = true"
              :disabled="!canManageCalendar || (!!showEventGroup && !!showEventGroup.eventGroups[0].fromBooking) || isLicenseBlocked || isOnlyWheelOfFortune"
            >
              <v-icon>mdi-calendar-edit</v-icon>
            </v-btn>
          </template>
          Cambia data all'evento
        </v-tooltip>
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon 
              class="ml-2"
              v-on="on"
              v-bind="attrs"
              @click="handleDuplicate"
              :disabled="!canManageCalendar || isLicenseBlocked || isOnlyWheelOfFortune"
            >
              <v-icon>mdi-calendar-blank-multiple</v-icon>
            </v-btn>
          </template>
          Duplica gli eventi
        </v-tooltip>
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon 
              class="ml-2"
              v-on="on"
              v-bind="attrs"
              @click="deleteAllServices"
              :disabled="!canManageCalendar || (!!showEventGroup && !!showEventGroup.eventGroups[0].fromBooking) || isLicenseBlocked || isOnlyWheelOfFortune"
            >
              <v-icon>mdi-close-box-multiple-outline</v-icon>
            </v-btn>
          </template>
          Rimuovi tutti i servizi dell'evento
        </v-tooltip>
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon 
              class="ml-2"
              v-on="on"
              v-bind="attrs"
              :disabled="!showCustomer"
              @click="showEventCustomerAnalytics = true"
            >
              <v-icon>mdi-chart-areaspline</v-icon>
            </v-btn>
          </template>
          Statistiche Cliente
        </v-tooltip>

        <StandardDialog
          v-model="editNewDateService"
          title="Sposta Evento - Selezionata data"
          dialog-width="500px"
          :dialog-height="null"
        > 
          <template v-slot:footer>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="editNewDateService = false">Chiudi</v-btn>
            <v-btn text color="default" @click="changeServicesDates" :disabled="!newChangeDataService">Salva</v-btn>
          </template>
          <DateTimePickerCircular
            label="Cambia data"
            :margin-top-time="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            v-model="newChangeDataService"
            filled
            class="align-center justify-center"
            style="width: 100%"
          ></DateTimePickerCircular>
        </StandardDialog>
      </template>
      <template v-slot:content>
        <div style="height: 100%; width: 100%">
          <EventGroupForm 
            v-if="editEventGroupForm" 
            v-model="showEventGroup" 
            class="py-2" 
            :loading="loadingForm" 
            :can-manage="canManageCalendar" 
            variant="edit"
            :filters="operatorFilter"
            :valid.sync="editEventGroupFormValid"
            :modCalendar="modCalendar"
            :split-in-service-phase="splitInServicePhase"
            :disabled="isLicenseBlocked || isOnlyWheelOfFortune"
          ></EventGroupForm>
          <SimplePopUp
            v-model="popUp"
            :alert-text="popUpText"
            :dismissible="false"
          >
            <template v-slot:footer>
              <v-row class="pt-7 d-flex justify-space-around">
                <v-btn
                  style="min-width: 30%;"
                  color="error"
                  @click="popUp = false"
                >
                  Annulla
                </v-btn>
                <v-btn
                  style="min-width: 30%;"
                  color="success"
                  @click="deleteEventGroupAfterControl()"
                >
                  Procedi
                </v-btn>
              </v-row>
            </template>
          </SimplePopUp>
        </div>
      </template>
      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="canManageCalendar" :loading="loadingAddToSaloonButton" text color="default" @click="addToSaloon" :disabled="alreadyAddedToSaloon || !editEventGroupFormValid">Aggiungi al salone</v-btn>
        <v-btn text color="error" @click="handleCloseEditEvent" :disabled="!editEventGroupFormValid">Chiudi</v-btn>
      </template>
    </FullScreenDialog>

    <StandardDialog
        v-model="operatorEventErrorDialog"
        :dialog-height="null"
        dialog-width="400px"
        title="Appuntamenti trovati"
        @close="operatorEventErrorDialog = false"
      >
        <v-alert 
          border="left"
          class="py-2 my-3"
          color="error"
        >
          L'operatore ha degli eventi nei giorni selezionati! 
          Spostarli prima di poter inserire l'evento extra.
        </v-alert>
        
      </StandardDialog>


    <!-- Nuovo evento custom -->
    <StandardDialog v-model="newEventForm" title="Nuovo Evento" :dialog-height="null" persistent>
      <EventForm v-model="newEvent" class="py-2" :loading="loadingForm" :calendar-type="this.calendarsOperator.filter((el, index) => this.selectedCalendarOperatorIndex.includes(index)).map(el => el.name)" :bus="eventFormBus"></EventForm>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="newEventForm = false">Annulla</v-btn>
        <v-btn 
          text 
          color="default"
          :disabled="!checkValidEventForm(newEvent)"
          @click="createEvent" 
          :loading="loadingForm"
        >
          Salva
        </v-btn>
      </template>

      <SimplePopUp
        v-model="popUpCustomEvent"
        :alert-text="popUpTextCustomEvent"
        :dismissible="false"
        width="40dvw"
      >
        <template v-slot:footer>
          <v-row class="pt-7 d-flex justify-space-around">
            <v-btn
              style="min-width: 30%;"
              color="error"
              @click="popUpCustomEvent = false"
            >
              Annulla
            </v-btn>
            <v-btn
              style="min-width: 30%;"
              color="success"
              @click="createEventAfterControl()"
            >
              Procedi
            </v-btn>
          </v-row>
        </template>
      </SimplePopUp>
    </StandardDialog>


    <!-- Modifica evento custom -->
    <StandardDialog
      v-model="editEventForm"
      title="Modifica Evento"
      :dialog-height="null"
      persistent
    >
      <EventForm v-model="editEvent" class="py-2" :loading="loadingForm" :calendar-type="this.calendarsOperator.filter((el, index) => this.selectedCalendarOperatorIndex.includes(index)).map(el => el.name)" :bus="eventFormBus"></EventForm>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="editEventForm = false">Annulla</v-btn>
        <v-btn text color="default" @click="updateEvent" :loading="loadingForm">Salva</v-btn>
      </template>
    </StandardDialog>

    <!-- Modifica invitati evento custom -->
    <StandardDialog v-model="inviteesForm" title="Invitati" :dialog-height="null" persistent>
      <InviteesForm
        :event-id="showEvent.id"
        class="py-2"
        :loading="loadingForm"
        @change-invitees="handleChangeInvitees"
      ></InviteesForm>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="inviteesForm = false">Chiudi</v-btn>
      </template>
    </StandardDialog>

    <!-- Modifica evento custom -->
    <StandardDialog v-model="showEventDialog" :title="showEvent.title" :dialog-height="null">
      <template v-slot:header-actions>
        <StandardDialog
          v-model="duplicateDataForm"
          title="Duplica Evento Extra - Seleziona una data"
          dialog-width="700px"
          :dialog-height="null"
        >
          <template v-slot:footer>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="duplicateDataForm = false">Chiudi</v-btn>
            <v-btn text color="default" @click="createDuplicateEvent(showEvent)">Conferma</v-btn>
          </template>
          <DuplicateDateForm
            :operator-selector="true"
            :operator-choice.sync="duplicateOperatorChoice"
            :duplicate-date.sync="duplicateDate"
            :end-date.sync="duplicateEndDate"
            :start-date.sync="duplicateStartDate"
            :rule-type.sync="duplicateRuleType"
            :days-of-week.sync="duplicateDaysOfWeeks"
            :days-of-month.sync="duplicateDaysOfMonth"
          ></DuplicateDateForm>
        </StandardDialog>
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn 
              icon 
              class="mr-2"
              v-on="on"
              v-bind="attrs"
              @click="handleDuplicate"
              :disabled="!canManageCalendar"
            >
              <v-icon>mdi-calendar-blank-multiple</v-icon>
            </v-btn>
          </template>
          Duplica evento Extra
        </v-tooltip>
        <v-btn icon :disabled="loadingDelete" v-on:click="handleEditEvent(showEvent)">
          <v-icon class="ml-2">mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon v-on:click="archiveEvent(showEvent)" class="ml-3" :loading="loadingDelete" color="red">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
      <EventShow :event="showEvent" @edit-invitees="openInviteesForm"></EventShow>
    </StandardDialog>

    <!-- Statistiche cliente -->
    <StandardDialog 
      v-model="showEventCustomerAnalytics" 
      :title="eventCustomerAnalyticDialogTitle" 
      :dialog-height="null"
      dialog-max-width="800px"
    >
      <CustomerAnalytics :customer="showCustomer"></CustomerAnalytics>
    </StandardDialog>

    <!-- Statistiche calendario -->
    <StandardDialog
      v-model="previsionalDialog"
      title="Statistiche"
      :dialog-height="null"
      dialog-max-width="500"
    >
      <CalendarAnalytics :date="previsionalDate"></CalendarAnalytics>
    </StandardDialog>

    <!-- Statistiche calendario -->
    <StandardDialog
      v-model="searchForCustomerDialog"
      title="Ricerca appuntamento per cliente"
      :dialog-height="null"
      dialog-max-width="700"
    >
      <CustomersAutocomplete 
        v-model="customerSelected"
        class="mt-2" 
        return-object
        :autoDetectNewCustomer="false"
        :external-search="customerFromVoice"
        @input="fetchEventsCustomer"
      ></CustomersAutocomplete>
      <TypeDataTable
        class="mt-5"
        loading-text="Caricamento lista ..."
        :headers="headersCustomerTable"
        :items="customerSelectedItems"
        height="50vh"
        :show-select="false"
        :deletable="false"
        :show-actions="true"
        :disable-pagination="true"
      >
        <template v-slot:actions="{ item }">
          <v-btn icon>
            <v-icon @click="deleteEvent(item,customerSelected)">mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:custom-operators="{ item }">
          <div v-if="!!item.eventServices[0]">
            <v-chip v-for="el in item.eventServices[0].operators" :key="el.id" class="ml-1">{{el.firstname}}</v-chip>
          </div>
        </template>
        
      </TypeDataTable>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="searchForCustomerDialog = false; customerSelectedItems = []; customerSelected = undefined;">Chiudi</v-btn>
      </template>
    </StandardDialog>

    <!-- Conferma SMS -->
    <StandardDialog
      v-model="confirmSmsDialog"
      :title="'Conferma SMS a ' + selectedSmsList.length + ' clienti'"
      dialog-height="100%"
      dialog-max-width="75%"
    >
      <div style="height: 70%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selectedSmsList"
          loading-text="Caricamento lista sms"
          :headers="headersConfirmSmsList"
          :items="filteredConfirmSmsList"
          item-key="eventGroupId"
          :height="dataTableHeight"
          show-select
          :single-select="false"
          :show-actions="false"
          :disable-pagination="true"
        >
          <template v-slot:custom-messageType="{ item }">
            <v-icon>{{item.messageType == 1 ? 'mdi-whatsapp' : 'mdi-message-fast'}}</v-icon>
          </template>
          
        </TypeDataTable>
      </div>
      <template v-slot:footer-actions>
        <v-btn color="error" text 
          @click="() => {
            confirmSmsDialog = false
            selectedSmsList = []
          }"
        >
          Chiudi
        </v-btn>

        <v-btn :disabled="selectedSmsList.length == 0" color="green" text @click="alertOverlay = true">
          Conferma
        </v-btn>
      </template>

          <!-- Overlay di conferma messaggi -->
      <v-overlay
        :value="alertOverlay"
      >
        <v-alert
          border="left"
          prominent
          style="max-width: 30vw;background-image: linear-gradient(45deg, rgb(138, 119, 226, 1), rgb(80, 191, 225,1));"
          icon="mdi-alert"
          dark
        >
          <v-col class="px-5">
            <v-row class="text-justify">
              {{ alertText }}
            </v-row>
            <v-row class="pt-7 d-flex justify-space-around">
              <v-btn
                style="min-width: 30%;"
                color="error"
                @click="alertOverlay = false"
              >
                Annulla
              </v-btn>
              <v-btn
                style="min-width: 30%;"
                color="success"
                @click="() => {
                  this.confirmSmsListMethod()
                  this.alertOverlay = false
                  this.selectedSmsList = []
                  this.confirmSmsDialog = false
                }"
              >
                Invia
              </v-btn>
            </v-row>
          </v-col>
        </v-alert>
      </v-overlay>
    </StandardDialog>


    <!-- Invia sms -->
    <StandardDialog
      v-model="smsDialog"
      title="Invia Sms"
      :dialog-height="null"
      dialog-max-width="800"
    >
      <SendSmsModel 
        class="mt-2"
        :customers="[showCustomer]"
        :numbers="!!showCustomer ? [showCustomer.prefixCellphone + showCustomer.cellphone] : undefined"
        :event-group="!!showEventGroup && Object.keys(showEventGroup).length > 0 ? showEventGroup : 
          !!newEventGroup && Object.keys(newEventGroup).length > 0 ? 
            {...newEventGroup, eventGroups: [{...newEventGroup, eventsInGroup: [newEventGroup]}]} : undefined"
      ></SendSmsModel>
    </StandardDialog>

    <v-snackbar v-model="snackbarModel">
      {{ snackbarMessage }}
      <template v-slot:action>
        <v-btn color="primary" text @click="snackbarModel = false">Close</v-btn>
      </template>
    </v-snackbar>

    <StandardDialog
      v-model="settings"
      title="Impostazioni"
      :dialog-height="null"
      dialog-max-width="800"
      persistent
    >
      <CalendarSettings
        @modCalendarChanged="modCalendarChanged"
      ></CalendarSettings>
    </StandardDialog>

    <StandardDialog
      v-model="operatorDialog"
      title="Ordine Operatori"
      :dialog-height="null"
      dialog-max-width="800"
      persistent
    >
      <OrderOperatorForm
      :bus="bus"
      >

      </OrderOperatorForm>
      <template v-slot:footer-actions>
        <v-btn color="error" text @click="closeOrderOperator"
          >Chiudi
        </v-btn>
          <v-btn
            color="default"
            text
            @click="changeOrder"
          >Conferma</v-btn
        >
      </template>
    </StandardDialog>

    <StandardDialog
      v-model="carpeDiemDialog"
      title="Carpe Diem"
      :dialog-height="null"
      dialog-max-width="800"
      persistent
    >
      <CarpeDiemSettings
      ></CarpeDiemSettings>
    </StandardDialog>
  </div>
</template>

<script>
import Vue from "vue";
import CategoriesCalendar from "@/components/calendar/CategoriesCalendar";
import WeekCalendar from "@/components/calendar/WeekCalendar";
import StandardDialog from "@/components/common/StandardDialog";
import FullScreenDialog from "@/components/common/FullScreenDialog";
import DateTimePickerCircular from '@/components/common/DateTimePickerCircular.vue';
import StandardMenu from "@/components/common/StandardMenu";
import EventForm from "@/components/calendar/EventForm";
import EventGroupForm from "@/components/calendar/EventGroupForm";
import InviteesForm from "@/components/calendar/InviteesForm";
import operatorManagment from "@/services/operators/operators.service.js";
import calendarManagment from "../services/calendar/calendar.service.js";
import eventGroupManagment from "@/services/calendar/eventGroup.service.js";
import EventShow from "@/components/calendar/EventShow.vue";
import EventGroupShow from "@/components/calendar/EventGroupShow.vue";
import StandardColorPicker from "@/components/common/StandardColorPicker.vue";
import { cloneDeep, uniqBy } from "lodash";
import colorConstrast from "@/mixins/common/colorContrast";
import eventServiceForm from "@/services/calendar/eventService.form.js";
import CalendarAnalytics from "@/components/calendar/CalendarAnalytics.vue";
import CustomerAnalytics from "@/components/calendar/CustomerAnalytics.vue"
import SendSmsModel from '@/components/calendar/SendSmsModel.vue';
import ListCalendar from '@/components/calendar/ListCalendar.vue';
import DuplicateDateForm from '@/components/calendar/DuplicateDateForm.vue'
import absencesService from "@/services/presences/absences/absences.service.js"
import CustomersAutocomplete from "@/components/common/CustomersAutocomplete.vue";
import TypeDataTable from '@/components/common/TypeDataTable.vue';
import settingService from "@/services/settings/settings.service.js";
import smsService from '@/services/sms/sms.service.js';
import CalendarSettings from "@/components/setting/Calendar.vue";
import shiftService from "@/services/presences/shifts/shifts.service.js";
import html2canvas from '@/assets/js/html2canvas.min.js'
import OrderOperatorForm from "@/components/calendar/OrderOperatorForm.vue";
import billService from "@/services/bills/bills.service";
import SimplePopUp from '@/components/common/SimplePopUp.vue'
import CarpeDiemSettings from "@/components/calendar/CarpeDiemSettings.vue";

export default {
  name: "Calendar",
  components: {
    CategoriesCalendar,
    StandardDialog,
    EventForm,
    InviteesForm,
    EventShow,
    EventGroupForm,
    EventGroupShow,
    StandardMenu,
    WeekCalendar,
    StandardColorPicker,
    CalendarAnalytics,
    DateTimePickerCircular,
    CustomerAnalytics,
    FullScreenDialog,
    SendSmsModel,
    ListCalendar,
    DuplicateDateForm,
    CustomersAutocomplete,
    TypeDataTable,
    CalendarSettings,
    OrderOperatorForm,
    SimplePopUp,
    CarpeDiemSettings
  },
  mixins: [colorConstrast],
  data: function() {
    return {
      focus: new Date(),
      events: [],
      loadingDelete: false,
      newEventForm: false,
      newEvent: this.newEventPrecompiled,
      newEventGroupForm: this.newEventGroupFormDialog,
      newEventGroupFormValid: false,
      newEventGroup: this.newEventGroupPrecompiled,
      editEventForm: false,
      editEvent: {},
      editEventGroupForm: false,
      editEventGroup: {},
      editEventGroupFormValid: false,
      showEventDialog: false,
      showEvent: {},
      showEventGroup: {},
      viewMenu: false,
      viewAppointmentMenu: false,
      currentViewIndex: 0,
      currentViewAppointmentIndex: 0,
      selectedCalendarOperatorIndex: [],
      selectedMessageMenuIndex: 0,
      operatorFilter: {notInStateOperator: 'disabled'},
      whatsappNumber: undefined,
      selectedCalendarOperator: false,
      selectedMessageMenu: false,
      selectionCalendarOperator: true,
      selectGlobal: true,
      loadingAddToSaloonButton: false,
      page: 1,
      rowPerPage: 100,
      totalPages: 100,
      headersCustomerTable: [
        { text: "Data", value: "startDate", type: "datetime" },
        { text: "Servizio Prenotato", value: "eventServices[0].service.name"},
        { text: "Operatore", value: "operators", type:"custom"},
      ],
      calendarsOperator: [
        {
          name: "Parrucchiere",
          text: "Calendario Parrucchiere",
          icon: "mdi-hair-dryer"
        },
        {
          name: "Estetista",
          text: "Calendario Estetisti",
          icon: "mdi-face-woman-shimmer"
        },
        {
          name: 'Management',
          text: 'Calendario Management',
          icon: 'mdi-account-tie'
        }
      ],
      messageOptions: [
        {
          name: "appointment",
          text: "Messaggi Appuntamenti",
          icon: "mdi-message-bookmark"
        },
      ],
      views: [
        {
          name: "operatorsView",
          text: "Vista Operatori",
          icon: "mdi-calendar-account"
        },
        { 
          name: "weekView", 
          text: "Vista Settimana", 
          icon: "mdi-calendar-week" 
        },
        {
          name: "listView",
          text: "Vista Elenco",
          icon: "mdi-format-list-bulleted"
        }
      ],
      newAppointmentViews: [
        {
          name: "newCustomAppointment",
          text: "Impegni Extra",
          icon: "mdi-calendar-account"
        },
        {
          name: "newAppointment",
          text: "Nuovo Appuntamento Salone",
          icon: "mdi-calendar-plus"
        },
      ],
      loadingForm: false,
      inviteesForm: false,
      marginTop: undefined,
      bus: new Vue(),
      eventFormBus: new Vue(),
      snackbarModel: false,
      snackbarMessage: "",
      calendarRoundToSetting: undefined,
      previsionalDialog: false,
      confirmSmsDialog: false,
      selectedSmsList: [],
      filteredConfirmSmsList: [],
      headersConfirmSmsList: [
        { text: "Cliente", value: "fullname"},
        { text: "Telefono", value: "cellphone"},
        { text: "Tipologia SMS", value: "messageType", type: "custom" },
        { text: "Messaggio", value: "message"},
      ],
      searchForCustomerDialog: false,
      previsionalDate: undefined,
      confirmSmsDate: undefined,
      newChangeDataService: undefined,
      editNewDateService: false,
      duplicateOperatorChoice: undefined,
      duplicateDate: undefined,
      duplicateDataForm: false,
      duplicateEndDate: undefined,
      duplicateStartDate: undefined,
      duplicateRuleType: undefined,
      duplicateDaysOfWeeks: undefined,
      duplicateDaysOfMonth: undefined,
      alreadyAddedToSaloon: false,
      showEventCustomerAnalytics: false,
      showCustomer: undefined,
      smsDialog: false,
      printLoading: false,
      companyTurns: undefined,
      unavailabilities: undefined,
      closinghours: false,
      temporaryStartDate: undefined,
      calendarPermissions: [],
      canManage: false,
      customerSelected: undefined,
      customerSelectedItems: [],
      appointmentModelSelect: undefined,
      birthdayModelSelect: undefined,
      closingHoursMessage: "",
      operatorEventErrorDialog: false,
      checkOperatorEvent: undefined,
      settings: false,
      operatorDialog: false,
      modCalendar: 'PRO',
      iconLock: 'mdi-lock',
      taxForSaloon: undefined,
      splitInServicePhase: false,
      alertOverlay: false,
      alertText: "Stai per fare un invio massivo di messaggi. Sei sicuro di volero fare?",
      popUp: false,
      popUpText: "",
      popUpCustomEvent: false,
      popUpTextCustomEvent: "",
      viewBooking: undefined,
      carpeDiemDialog: false,
      isLicenseBlocked: undefined,
      isOnlyWheelOfFortune: undefined,
      selectedColumnOperator: undefined,
    };
  },
  props: {
    newEventGroupFormDialog: {
      type: Boolean,
      default: false
    },
    newEventPrecompiled: {
      type: Object,
      default: () => ({})
    },
    newEventGroupPrecompiled: {
      type: Object,
      default: () => ({})
    },
    customerFromVoice: {
      type: String,
      default: undefined
    },
    calendarDateFromVoice: {
      type: String,
      default: undefined
    }
  },
  mounted: async function() {
    let reloadEventsHandler = () => {
      this.bus.$emit("reload-events");
    };
    reloadEventsHandler = reloadEventsHandler.bind(this);
    eventServiceForm.on("reload", reloadEventsHandler);
    this.$on("hook:destroyed", () => eventServiceForm.off('reload', reloadEventsHandler));

    let eventGroupChangeHandler = ({ group }) => {
      this.alreadyAddedToSaloon = (group.status == 'accepted' || group.status == 'paid')
      this.showCustomer = !group ? undefined : group.customer
    }
    eventGroupChangeHandler = eventGroupChangeHandler.bind(this)
    eventServiceForm.on("change-group", eventGroupChangeHandler);
    this.$on("hook:destroyed", () => eventServiceForm.off('change-group', eventGroupChangeHandler));

    if (localStorage.getItem("selectedCalendarOperatorIndex")) {
      try {
        this.selectedCalendarOperatorIndex = JSON.parse(localStorage.getItem("selectedCalendarOperatorIndex"))
      } catch(e) {
        localStorage.removeItem("selectedCalendarOperatorIndex")
      }
    } else {
      this.selectedCalendarOperatorIndex = [...this.calendarsOperator.keys()]
    }
    this.selectedCalendar()
    this.bus.$on('fetchOrder', this.fetchOrder)

    let eventCachedChangesHandler = () => {
      this.bus.$emit('reload-events')
    }
    calendarManagment.addListener('events-cached-changes', eventCachedChangesHandler)

    let operatorsCachedChangesHandler = () => {
      this.fetchOrder()
    }
    calendarManagment.addListener('operators-cached-changes', operatorsCachedChangesHandler)

    let eventsCachedChangesHandler = () => {
      this.bus.$emit('reload-events')
    }
    eventGroupManagment.addListener('events-cached-changes', eventsCachedChangesHandler)

    let absencesCachedChangesHandler = () => {
      this.bus.$emit('reload-unavailabilities')
    }
    absencesService.addListener('absences-cached-changes', absencesCachedChangesHandler)
    
    this.$on("hook:destroyed", () => {
      calendarManagment.removeListener('events-cached-changes', eventCachedChangesHandler)
      calendarManagment.removeListener('operators-cached-changes', operatorsCachedChangesHandler)
      eventGroupManagment.removeListener('events-cached-changes', eventsCachedChangesHandler)
      absencesService.removeListener('absences-cached-changes', absencesCachedChangesHandler)
    })
    
    if(!!this.customerFromVoice) {
      this.searchForCustomerDialog = true
    }
  },
  methods: {
    loadSettings: async function () {
      settingService.list("company").then((list) => {
        let settings = [];
        for (const [key, value] of Object.entries(list)) {
          settings.push({
            key: key,
            value: value.value,
          });
        }
        this.settings = settings;
      });
    },
    checkValidEventForm(eventForm) {
      if (!eventForm) return false;
      if (!eventForm.title) return false;
      if (!eventForm.startDate) return false;
      if (!eventForm.endDate) return false;
      if (!eventForm.organizerId && (eventForm.allOperators == false || eventForm.allOperators == undefined)) return false;
      if (!!eventForm.organizerId && eventForm.organizerId.length == 0 && (eventForm.allOperators == false || eventForm.allOperators == undefined)) return false;
      return true;
    },
    async unavailabilitiesFetcher({ from, to }) {
      let unavailabilities = []

      // puts store unavailabilities
      if(!!this.companyTurns) {
        const weekday = new Date(from).getDay()
        if(!!this.companyTurns[weekday]) {
          if(this.companyTurns[weekday].closed) {
            unavailabilities.push({
              from: '00:00',
              to: '23:59',
            })
          } else {
            const { afternoon, morning } = this.companyTurns[weekday].hours
            unavailabilities.push({
              from: '00:00',
              to: this.convertAmPmTo24HoursFormat(morning.open),
            })

            unavailabilities.push({
              from: this.convertAmPmTo24HoursFormat(morning.close),
              to: this.convertAmPmTo24HoursFormat(afternoon.open),
            })

            unavailabilities.push({
              from: this.convertAmPmTo24HoursFormat(afternoon.close),
              to: '23:59',
            })

          }
        }
      }

      // puts absences unavailabilities
      const absences = (await absencesService.cachedList({
        start: from,
        end: to,
      }))[0].rows

      for(let i = 0; i < absences.length; i += 1) {
        let fromTime
        if(new Date(absences[i].start) < new Date(from)) {
          fromTime = this.dateToTimeString(new Date(from))
        } else {
          fromTime = this.dateToTimeString(new Date(absences[i].start))
        }

        let toTime
        if(new Date(absences[i].end) > new Date(to)) {
          toTime = this.dateToTimeString(new Date(to))
        } else {
          toTime = this.dateToTimeString(new Date(absences[i].end))
        }

        unavailabilities.push({
          category: Number(absences[i].operatorId),
          from: fromTime,
          to: toTime
        })
      }

      // puts end turn unavailabilities
      const turns = await shiftService.cachedList({
        start: from,
        end: to
      })

      for(let i = 0; i < turns.length;i++){
        if(turns[i].timeTable.timeTablesTurns.length===1){
          unavailabilities.push({
            category: Number(turns[i].operatorId),
            from: '00:00',
            to: turns[i].timeTable.timeTablesTurns[0].start.slice(0,5)
          })

          unavailabilities.push({
            category: Number(turns[i].operatorId),
            from: turns[i].timeTable.timeTablesTurns[0].stop.slice(0,5),
            to: '23:59'
          })
        }
        else{
          unavailabilities.push({
            category: Number(turns[i].operatorId),
            from: '00:00',
            to: turns[i].timeTable.timeTablesTurns[0].start.slice(0,5)
          })

          for(let j = 1; j < turns[i].timeTable.timeTablesTurns.length - 1; j++)
            unavailabilities.push({
              category: Number(turns[i].operatorId),
              from: turns[i].timeTable.timeTablesTurns[j-1].stop.slice(0,5),
              to: turns[i].timeTable.timeTablesTurns[j].start.slice(0,5)
            })

          unavailabilities.push({
            category: Number(turns[i].operatorId),
            from: turns[i].timeTable.timeTablesTurns[turns[i].timeTable.timeTablesTurns.length - 2].stop.slice(0,5),
            to: turns[i].timeTable.timeTablesTurns[turns[i].timeTable.timeTablesTurns.length - 1].start.slice(0,5)
          })
        }

      }

      const extraordinaryClosure = (await calendarManagment.getExtraordinaryClosure({
        from: from,
        to: to,
      })).rows

      for(let i = 0; i < extraordinaryClosure.length; i += 1) {
        let date = new Date(extraordinaryClosure[i].date)
        const day = new Date(from)
        if(date.getDate() === day.getDate() && date.getMonth() === day.getMonth() && date.getFullYear() === day.getFullYear())
          unavailabilities.push({
            from: '00:00',
            to: '23:59',
          })
      }


      this.unavailabilities = unavailabilities
      return unavailabilities
    },
    dateToTimeString(date) {
      return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
    },
    convertAmPmTo24HoursFormat(time) {
      if(time.includes(' am')) {
        return time.replace(' am', '')
      } else {
        return this.addMinutes(time.replace(' pm', ''), 720)
      }
    },
    addMinutes(time, minutesToAdd) {
      if(time == '00:00') return '00:00'

      let piece = time.split(':');
      let mins = piece[0]*60 + +piece[1] + +minutesToAdd;

      return (mins%(24*60)/60 | 0) + ':' + (mins%60);
    },
    nameFetcher(event) {
      let name = [];

      if (event.custom) {
        if (!!event.title) name.push(event.title);
        if (!!event.description) name.push(event.description);
      } else {
        if (
          !!event.eventGroups &&
          event.eventGroups.length != 0 &&
          !!event.eventGroups[0].customer
        ) {
          let lastname = event.eventGroups[0].customer.lastname
          let firstname = event.eventGroups[0].customer.firstname
          if (event.eventGroups[0].customer.lastname.length + event.eventGroups[0].customer.firstname.length > 12) {
            if (event.eventGroups[0].customer.lastname.length > 12) {
              lastname = event.eventGroups[0].customer.lastname.substring(0, 11)
            } 
            else {
              firstname = event.eventGroups[0].customer.firstname.substring(0, 11)
            }
          }
          name.push(
            `${lastname} ${firstname}`
          );
        }
      }
      return name.join(' ')
    },
    detailFetcher(eventReference) {
      if (!this.events[eventReference.index]) return undefined;
      const event = this.events[eventReference.index];
      let service = []

      if (!!event.eventServices && event.eventServices.length != 0) {
        let serviceName = event.eventServices[0].service.name;
        if (!!event.eventServices[0].phase)
          serviceName += " (" + event.eventServices[0].phase.name + ")";
        service.push(serviceName);
      }

      return service.join(' ')
    },
    colorFetcher(event) {
      if(!!event.eventGroups && event.eventGroups.length > 0 && !!event.eventGroups[0] && event.eventGroups[0].status == "paid") {
        return "#c0c0c0";
      }
      if (event.custom && !!event.organizer) {
        return "#a7f09c";
      }
      if (!!event.eventServices[0] && !!event.eventServices[0].service) {
        return event.eventServices[0].service.color;
      } else {
        return event.eventGroups[0].color;
      }
    },
    categoryFetcher(event) {
      if (event.custom && !event.cloned) {
        return event.organizer.id.toString();
      }

      if (event.custom && !!event.cloned) {
        return event.categoryInvetee.operatorId.toString();
      }

      if (
        !event.custom &&
        !!event.eventServices[0] &&
        !!event.eventServices[0].operators
      ) {
        const operatorIds = event.eventServices[0].operators.map(el => {
          return el.id.toString();
        });
        return operatorIds[0] ? operatorIds[0] : undefined;
      } else {
        return undefined;
      }
    },
    categorySetter(event, newcategory, categoryWhereItStarted) {
      if (event.custom) {
        event.organizer = newcategory;
        event.organizerId = newcategory.id;
      } else if (
        !!event.eventServices[0] &&
        !!event.eventServices[0].operators
      ) {
        if (
          !event.eventServices[0].operators
            .map(el => el.id.toString())
            .includes(newcategory.id.toString())
        ) {

          if(event.eventServices[0].operators.length > 1){
            if(event.multipleOperatorsModified)
              event.eventServices[0].operators.shift()
            event.eventServices[0].operators = [newcategory, ...event.eventServices[0].operators.filter(el => el.id != categoryWhereItStarted)];
            event.multipleOperatorsModified = true;
          }
           else 
            event.eventServices[0].operators = [newcategory];
        }
      }
    },
    printAppointments() {
      
      this.printLoading = true

      let beginningOfDay = new Date(this.focus);
      html2canvas(document.getElementById("calendar"), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Appuntamenti_" + beginningOfDay.getDate() + "-" + beginningOfDay.getMonth() + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    },
    async fetchAllOperators() { 
      return await operatorManagment.cachedList({
        filters: {
          notInStateOperator:'disabled',
          calendar: {
            name: 'Global'
          }
        }
      })
    },
    async fetchOperators() {
      let operators = await operatorManagment.cachedList({filters: !!this.operatorFilter ? this.operatorFilter : { notInStateOperator: 'disabled' }})
      return operators
    },
    fetchOperatorsAlt() {
      return this.fetchOperators()
    },
    selectedCalendar() {
      this.$nextTick(() => {
        this.operatorFilter = {
          notInStateOperator: 'disabled',
          calendar:
            {
              name: this.calendarsOperator.filter((el, index) => this.selectedCalendarOperatorIndex.includes(index)).map(el => el.name)
            }
        }
        localStorage.setItem('selectedCalendarOperatorIndex', JSON.stringify(this.selectedCalendarOperatorIndex))
        this.selectionCalendarOperator = !this.selectionCalendarOperator
        this.bus.$emit("reload-events");
      })
    },
    selectedMessageOptions(typeMessage) {
      if(typeMessage == "birthday") {
        if(!!this.birthdayModelSelect && !!this.whatsappNumber) {
          let birthday = new Date(this.focus)
          birthday.setHours(0)
          smsService.sendBirthdayMessage(this.whatsappNumber, birthday, this.birthdayModelSelect).then((result) => {
            this.$delegates.snackbar("Messaggio compleanni inviati con successo");
          })
        } else {
          this.$delegates.snackbar("Manca il modello Sms o il numero del negozio");
          return
        }
      } else if(typeMessage == "appointment") {
        smsService.confirmSmsList(this.focus, this.appointmentModelSelect).then((result) => {
          this.filteredConfirmSmsList = result
        })
        this.openConfirmSmsDialog()
      }
    },
    confirmSmsListMethod() {
      let smsCustomerList = [];
      for(let i = 0; i < this.selectedSmsList.length; i++) {
        smsCustomerList.push(this.selectedSmsList[i].customerId)
      }
      if(!!this.appointmentModelSelect && !!this.whatsappNumber) {
        smsService.sendAppointmentMessage(this.whatsappNumber, this.focus, this.operatorFilter, this.appointmentModelSelect, smsCustomerList).then((result) => {
          this.$delegates.snackbar("Messaggi Appuntamenti Inviati con successo");
          this.confirmSmsDialog = false;
        })
      } else {
        this.$delegates.snackbar("Manca il modello Sms o il numero del negozio");
        return
      }
    },
    fetchEventsForCategories(start, end) {
      return new Promise((resolve, reject) => {
        calendarManagment
          .cachedEventsList({
            start: new Date(start), 
            end: new Date(end), 
          })
          .then(events => {
            let eventsToAdd = [];
            for (let i = 0; i < events.length; i++) {
              events[i].timed = !events[i].allDay;
              events[i].startDate = new Date(events[i].startDate);
              events[i].endDate = new Date(events[i].endDate);

              if (
                !!events[i].eventServices[0] &&
                events[i].eventServices[0].operators.length > 1
              ) {
                for (
                  let k = 1;
                  k < events[i].eventServices[0].operators.length;
                  k += 1
                ) {
                  let newEvent = cloneDeep(events[i]);
                  newEvent.eventServices[0].operators = [];
                  newEvent.eventServices[0].operators.push(
                    events[i].eventServices[0].operators[k]
                  );
                  newEvent.eventServices[0].operators.push(
                    ...events[i].eventServices[0].operators.filter((el, i) => {
                      return i != k;
                    })
                  );
                  eventsToAdd.push(newEvent);
                }
              } else if (!!events[i].custom && events[i].invitees.length > 0) {
                for (let k = 0; k < events[i].invitees.length; k += 1) {
                  if(events[i].invitees[k].operatorId == events[i].organizerId)
                    continue

                  let newEvent = cloneDeep(events[i]);
                  newEvent.cloned = true;
                  newEvent.categoryInvetee = events[i].invitees[k];
                  eventsToAdd.push(newEvent);
                }
              }
            }
            this.events = events;
            this.events.push(...eventsToAdd);
            resolve(events);
          });
      });
    },
    fetchEventsForWeek(start, end) {
      let localEnd = new Date(end)
      localEnd.setHours(23)
      localEnd.setMinutes(59)
      localEnd.setSeconds(59)
      return new Promise((resolve, reject) => {
        calendarManagment
          .eventsList({
            start: new Date(start),
            end: new Date(localEnd)
          })
          .then(events => {
            for (let i = 0; i < events.length; i++) {
              events[i].timed = !events[i].allDay;
              events[i].startDate = new Date(events[i].startDate);
              events[i].endDate = new Date(events[i].endDate);
            }
            resolve(events);
          });
      });
    },
    fetchEventsForList(start, end, filters) {
      return eventGroupManagment
        .list(new Date(start), new Date(end), {
          custom: false,
          ...filters
        })
        .then(groups => {
          for(let k = 0; k < groups.length; k += 1) {
            for (let i = 0; i < groups[k].events.length; i++) {
              groups[k].events[i].startDate = new Date(groups[k].events[i].startDate);
              groups[k].events[i].endDate = new Date(groups[k].events[i].endDate);
            }
          }
          return uniqBy(groups, 'id');
        });
    },
    handleListCalendarShow(eventGroup) {
      eventGroup.eventsInGroup = eventGroup.events
      this.showEventGroup = { ...eventGroup };
      if (!!eventGroup) {
        eventServiceForm.initialize("update", { ...eventGroup });
        this.editEventGroupForm = true;
      }
    },
    handleCalendarShow(params) {
      if (params.custom) {
        this.handleShowEvent(params);
      } else {
        this.handleShowEventGroup(params);
      }
    },
    handleShowEvent(event) {
      this.showEvent = event;
      this.showEventDialog = true;
    },
    handleShowEventGroup(eventGroup) {
      this.showEventGroup = { ...eventGroup };
      if (eventGroup.eventGroups && eventGroup.eventGroups.length != 0) {
        eventServiceForm.initialize("update", { ...eventGroup.eventGroups[0] });
        this.editEventGroupForm = true;
      }
    },
    handleNewDateService(newDate) {},
    handleEndDrag(event, oldEvent) {
      if (!this.canManageCalendar) {
        return;
      }
      if(!event || !event.startDate) {
        return;
      }
      event.startDate = new Date(event.startDate);
      event.endDate = new Date(event.endDate);
      let newEventOperatorIds

      let promises = [];
      if (event.custom) {
        promises.push(calendarManagment.updateEvent(event));
      } else {
        if(oldEvent.startDate.getTime() != event.startDate.getTime() || oldEvent.endDate.getTime() != event.endDate.getTime())
          promises.push(calendarManagment.updateEvent(event));

        let operatorsDifferent = false;
        if (
          !!event.eventServices[0] &&
          !!event.eventServices[0].operators &&
          !!oldEvent &&
          !!oldEvent.eventServices[0] &&
          !!oldEvent.eventServices[0].operators
        ) {
          newEventOperatorIds = event.eventServices[0].operators.map(el =>
            el.id.toString()
          );
          const oldEventOperatorIds = oldEvent.eventServices[0].operators.map(
            el => el.id.toString()
          );
          operatorsDifferent =
            newEventOperatorIds.length != oldEventOperatorIds.length ||
            oldEventOperatorIds.some(id => !newEventOperatorIds.includes(id));
        }

        if (operatorsDifferent && newEventOperatorIds.length == 1) {
          promises.push(
            eventGroupManagment.setOperator(event, [
              { id: this.categoryFetcher(event) }
            ])
          );
        }
       else if(operatorsDifferent && newEventOperatorIds.length > 1) {
          promises.push(
            eventGroupManagment.setOperator(event, newEventOperatorIds.map(el => {
              let obj = {}
              obj.id = el
              return obj
            }))
          );
        } 
      }

      Promise.all(promises).then(() => { });
      this.iconLock = 'mdi-lock'
    },
    handleNewEvent(prefilled) {
      this.newEvent = {};

      if (!!prefilled) {
        this.newEvent.startDate = !!prefilled.startDate
          ? prefilled.startDate
          : undefined;
        this.newEvent.endDate = !!prefilled.endDate
          ? prefilled.endDate
          : undefined;
        this.newEvent.organizerId = !!prefilled.category
          ? prefilled.category.id
          : undefined;
      }
      this.newEventForm = true;
    },
    checkClosingHours(prefilled, appointmentDate, mode) {
      if(this.isLicenseBlocked || this.isOnlyWheelOfFortune)
        return

      let alertOpenDate = new Date(this.focus), alertCloseDate = new Date(this.focus), open, close;
      if(!!this.unavailabilities) {
        if(this.unavailabilities.length > 0){
          for(let k = 0; k < this.unavailabilities.length; k++) {

            if(mode === "newEventGroup") {
              const unavailabilityFrom = new Date(prefilled.startDate)
              unavailabilityFrom.setHours(this.unavailabilities[k].from.split(':')[0])
              unavailabilityFrom.setMinutes(this.unavailabilities[k].from.split(':')[1])
              const unavailabilityTo = new Date(prefilled.startDate)
              unavailabilityTo.setHours(this.unavailabilities[k].to.split(':')[0])
              unavailabilityTo.setMinutes(this.unavailabilities[k].to.split(':')[1])

              const isUnavailable = unavailabilityFrom < prefilled.startDate && unavailabilityTo > prefilled.startDate
              if(!!this.unavailabilities[k].category && prefilled.category.id == this.unavailabilities[k].category && isUnavailable) {
                this.closingHoursMessage = "Si sta per creare un appuntamento durante l'orario di assenza di " + prefilled.category.firstname;
                this.closinghours = true
                this.temporaryStartDate = appointmentDate
                return
              }
            }

          }

          for(let i = 0; i < this.unavailabilities.filter(el => !el.category).length; i++) {
            open = (this.unavailabilities[i].from.split(":"))
            alertOpenDate.setHours(open[0])
            alertOpenDate.setMinutes(open[1])

            close = (this.unavailabilities[i].to.split(":"))
            alertCloseDate.setHours(close[0])
            alertCloseDate.setMinutes(close[1])
            if ( appointmentDate >= alertOpenDate && appointmentDate < alertCloseDate) {
              this.closingHoursMessage = "Si sta per creare un appuntamento durante l'orario di chiusura del negozio";
              this.closinghours = true
              this.temporaryStartDate = appointmentDate
              return
            }
          }
        }
      }

      if (mode === "newEventGroup") {
        this.handleNewEventGroup({ 
          startDate: appointmentDate,
          category: prefilled.category
        })
      } else if (mode === "createEventGroup") {
        this.createEventGroup()
      }
    },
    closeAlertDialog() {
      this.temporaryStartDate = undefined; 
      this.closinghours = false
    },
    passAlertDialog() {
      this.closinghours = false
      if (this.newEventGroup.startDate) 
        this.createEventGroup()
      else
        this.handleNewEventGroup({startDate: this.temporaryStartDate})
    },
    handleNewEventGroup(prefilled) {
      if (!this.canManageCalendar) {
        this.$delegates.snackbar("Non si hanno i permessi per eseguire quest'azione");
        return;
      }
      this.newEventGroup = {
        startDate: prefilled.startDate
      };


      this.selectedColumnOperator = prefilled.category

      eventServiceForm.initialize("create", this.newEventGroup);
      this.newEventGroupForm = true;
    },
    wrapperNewEventGroup(prefilled) {
      if (this.newEventGroup.startDate) {
        this.checkClosingHours(prefilled, this.newEventGroup.startDate, "createEventGroup")
      } else {
        this.checkClosingHours(prefilled, prefilled.startDate, "newEventGroup")
      }
    },
    handleEditEvent(event) {
      let clonedEvent = { ...event };
      clonedEvent.startDate = new Date(clonedEvent.startDate);
      clonedEvent.endDate = new Date(clonedEvent.endDate);
      clonedEvent.organizerId = clonedEvent.organizer.id;
      this.editEvent = clonedEvent;
      this.editEventForm = true;
    },
    handleChangeInvitees(invitees) {
      this.showEvent.invitees = invitees;
      this.bus.$emit("reload-events");
    },
    archiveEvent(event) {
      this.loadingDelete = true;

      let confirmed = confirm("sei sicuro di voler eliminare l'evento?");

      if (confirmed) {
        calendarManagment.archiveEvent(event).then(result => {
          this.showEventDialog = false;
          this.showEvent = {};
          this.bus.$emit("reload-events");
          this.loadingDelete = false;
        });
      }
    },
    archiveEventGroup(eventGroup) {
      let confirmed = confirm("sei sicuro di voler eliminare il gruppo?");

      if (confirmed) {
        this.loadingDelete = true;
        this.loadingDelete = false;
      }
    },
    createEventGroup() {
        this.loadingForm = true;
        eventServiceForm
          .save()
          .then(() => {
            this.bus.$emit("reload-events");
          })
          .finally(() => {
            this.newEventGroup = {};
            this.$refs.newEventGroupRef.handleCustomerChange(undefined)
            this.loadingForm = false;
            this.newEventGroupForm = false;
          });
    },
    handleCancelEventGroupForm(){
      this.temporaryStartDate = undefined;
      this.newEventGroup = {};
      this.newEventGroupForm = false;
    },
    async createEvent() {
      let eventsList = await calendarManagment.eventsList({
        start: new Date(this.newEvent.startDate),
        end: new Date(this.newEvent.endDate)
      })

      if(this.newEvent.startDate > this.newEvent.endDate) {
        this.$delegates.snackbar("La data di  inizio deve precedere la data di fine");
        return
      } else if((!this.newEvent.organizerId || (!!this.newEvent.organizerId && this.newEvent.organizerId.length == 0))
        && !this.newEvent.allOperators) {
        this.$delegates.snackbar("Scegliere uno o più operatori oppure selezionarli tutti");
        return
      } else if(!this.newEvent.title){
        this.$delegates.snackbar("Inserire il titolo");
        return
      } else if(eventsList.length > 0) {
        this.checkOperatorEvent = false;
        for(let i = 0; i < eventsList.length; i++) {
          if(!!eventsList[i].eventServices && !!eventsList[i].eventServices[0] && 
            !!this.newEvent.organizerId && this.newEvent.organizerId.length > 0 && eventsList[i].eventServices.length > 0 && !eventsList[i].organizerId) {
            for(let k = 0; k < eventsList[i].eventServices[0].operators.length; k++) {
              if(eventsList[i].eventServices[0].operators.length > 0 && this.newEvent.organizerId.includes(eventsList[i].eventServices[0].operators[k].id)) {
                this.checkOperatorEvent = true;
              }
            }
          }
        }
      }

      if(!!this.checkOperatorEvent) {
        this.operatorEventErrorDialog = true
      } else {
        this.popUpTextCustomEvent = `Verrà creato un solo evento con data di inizio 
          ${new Date(this.newEvent.startDate).toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })} 
          e data di fine ${new Date(this.newEvent.endDate).toLocaleDateString('it-IT', 
            { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}`
        this.popUpCustomEvent = true
      }
    },
    async createEventAfterControl() {
      this.newEvent.calendar = this.calendarsOperator.filter((el, index) => this.selectedCalendarOperatorIndex.includes(index)).map(el => el.name);
      this.loadingForm = true;
      calendarManagment.createCustomEvent(this.newEvent).then(_ => {
        this.bus.$emit("reload-events");
        this.loadingForm = false;
        this.newEventForm = false;
        this.popUpCustomEvent = false
        this.popUpTextCustomEvent = ""
        
        this.newEvent = {}
      });
    },
    updateEvent() {
      this.loadingForm = true;
      calendarManagment.updateEvent(this.editEvent).then(_ => {
        this.bus.$emit("reload-events");
        this.loadingForm = false;
        this.editEventForm = false;
        this.showEventDialog = false;
      });
    },
    openInviteesForm() {
      this.inviteesForm = true;
    },
    snackbar(message) {
      this.snackbarMessage = message;
      this.snackbarModel = true;
    },
    openPrevisionalDialog() {
      this.previsionalDialog = true;
      this.previsionalDate = new Date(this.focus);
    },
    openConfirmSmsDialog() {
      this.confirmSmsDialog = true;
      this.confirmSmsDate = new Date(this.focus);
    },
    openSearchForCustomerDialog() {
      this.searchForCustomerDialog = true;
    },
    openSettings() {
      this.settings = true;
    },
    changeServicesDates() {
      this.editNewDateService = false;
      try {
        eventServiceForm.changeServicesDates(this.newChangeDataService);
      } catch (error) {
        console.log(error)
      }
      this.newChangeDataService = undefined
    },
    handleDuplicate() {
      this.duplicateStartDate = !!this.showEventGroup.startDate ? new Date(this.showEventGroup.startDate) : undefined;
      this.duplicateDataForm = true;
      this.duplicateEndDate = undefined
      this.duplicateRuleType = 'once'
      this.duplicateDaysOfWeeks = []
      this.duplicateDaysOfMonth = []
    },
    createDuplicateEvent(event) {
      calendarManagment.duplicateEvent(event.eventGroups, {
        ruleType: this.duplicateRuleType,
        startDate: this.duplicateRuleType == "once" ? this.duplicateDate : this.duplicateStartDate,
        endDate: this.duplicateEndDate,
        daysOfWeek: this.duplicateDaysOfWeeks,
        daysOfMonth: this.duplicateDaysOfMonth,
        operatorChoice: this.duplicateOperatorChoice,
        calendarType: this.newEvent.calendar = this.calendarsOperator.filter((el, index) => this.selectedCalendarOperatorIndex.includes(index)).map(el => el.name),
      }).then(_ => {
        this.bus.$emit("reload-events");
        this.duplicateDataForm = false;
        this.editEventForm = false;
      });
    },
    createDuplicate() {
      eventGroupManagment.duplicateEvents(eventServiceForm.eventGroup, {
        ruleType: this.duplicateRuleType,
        startDate: this.duplicateRuleType == "once" ? this.duplicateDate : this.duplicateStartDate,
        endDate: this.duplicateEndDate,
        daysOfWeek: this.duplicateDaysOfWeeks,
        daysOfMonth: this.duplicateDaysOfMonth,
      }).then(_ => {
        this.bus.$emit("reload-events");
        this.duplicateDataForm = false;
        this.editEventGroupForm = false;
      });
    },
    deleteAllServices() {
      eventServiceForm.removeAllServices().then((updatedEventGroup) => {
        this.bus.$emit("reload-events");
      })
    },
    async deleteEventGroup(eventGroup) {
      let localEventGroup = eventGroup != undefined ? eventGroup : eventServiceForm.eventGroup

      if(!!localEventGroup.fromBooking){
        this.popUp = true
        this.popUpText = `L'evento è stato creato dal Booking, chiamare il cliente per avvisarlo\nSei sicuro di voler eliminare l'evento?`
      } else
        await eventGroupManagment.deleteGroup(localEventGroup, false).then(() => {
          this.editEventGroupForm = false;
          this.bus.$emit("reload-events");
        })
    },
    async deleteEventGroupAfterControl() {
      this.popUp = false;
      let localEventGroup = eventServiceForm.eventGroup

      await eventGroupManagment.deleteGroup(localEventGroup, true).then(() => {
        this.editEventGroupForm = false;
        this.bus.$emit("reload-events");
      })
    },
    handleCloseEditEvent() {
      this.editEventGroupForm = false;
    },
    async addToSaloon() {
      this.loadingAddToSaloonButton = true;
      let checkBillALreadyLinked
      try {
        checkBillALreadyLinked = await billService.todayList(1, 500, { 
          customer: {
            id: eventServiceForm.eventGroup.customer.id
          },
          eventGroupId: eventServiceForm.eventGroup.id
        })
      } catch (error) {
        console.log(error)
      }
            
      if(!!checkBillALreadyLinked && checkBillALreadyLinked.rows.length == 0){
        let tax = this.taxForSaloon === undefined || this.taxForSaloon === null ? 22 : this.taxForSaloon
        eventGroupManagment.addToSaloon(eventServiceForm.eventGroup, tax).then(() => {
          this.bus.$emit("reload-events");
          this.editEventGroupForm = false
          this.newEventGroupForm = false
          this.$delegates.snackbar("Evento aggiunto al salone")
          this.loadingAddToSaloonButton = false;
          billService.reloadAllTodayCachedFilterList()
        }).catch((error) => { 
          console.log(error)
          this.loadingAddToSaloonButton = false;
        })
      }
      else if(!!checkBillALreadyLinked && checkBillALreadyLinked.rows.length > 0){
        this.loadingAddToSaloonButton = false;
        this.$delegates.snackbar("Evento già presente nel salone")
        billService.reloadAllTodayCachedFilterList()
      }
      
    },
    openSendSmsDialog(type) {
      if (type == 'newAppointment')
        this.showEventGroup = {}

      this.smsDialog = true
    },
    openNewAppointment(choice) {
      if(choice.name == "newCustomAppointment") {
        let localStartDate = new Date(this.focus)
        let today = new Date()
        localStartDate.setHours(today.getHours(), today.getMinutes())
        this.handleNewEvent({startDate:localStartDate, endDate: new Date(localStartDate.getTime() + 3600000)})
      } else {
        this.handleNewEventGroup({startDate : new Date()})
      }
    },
    async fetchEventsCustomer(customer){
      if(!customer)
        return

      let aYearFromNow = new Date()
      aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 2)
      this.customerSelectedItems = await calendarManagment.eventsList({
        start: new Date(),
        end: aYearFromNow,
        filters: { customer: customer }
      })
    },
    async deleteEvent(event, customer){
      try {
        if(event.eventGroups[0].eventsInGroup.length > 1)
          await eventServiceForm.removeServiceExternal(event.eventServices[0])  
        else
          await this.deleteEventGroup(event.eventGroups[0])
        await this.fetchEventsCustomer(customer)
        this.bus.$emit("reload-events");
      } catch (error) {
        console.log(error)
      }
    },
    openOrderOperator(){
      this.operatorDialog = true
    },
    closeOrderOperator(){
      this.operatorDialog = false
      this.bus.$emit('closeOrder')
    },
    changeOrder(){
      this.operatorDialog = false
      this.bus.$emit('changeOrder')
    },
    fetchOrder(){
      this.bus.$emit("reload-categories");
      this.bus.$emit("reload-events");
    },
    modCalendarChanged(modCalendar){
      this.modCalendar = modCalendar
    },
    changeLock(){
      this.iconLock == 'mdi-lock' ? this.iconLock = 'mdi-lock-open' : this.iconLock = 'mdi-lock'
    },
  },
  computed: {
    currentView() {
      return this.views[this.currentViewIndex].name;
    },
    eventCustomerAnalyticDialogTitle() {
      return !!this.showCustomer ? `${this.showCustomer.firstname} ${this.showCustomer.lastname}` : undefined
    },
    canManageCalendar() {
      return this.calendarPermissions.includes('manage')
    },
    canViewCalendar() {
      return this.calendarPermissions.includes('view')
    },
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    }
  },
  watch: {
  },
  settings: {
    calendarRoundTo: {
      bind: "calendarRoundToSetting"
    },
    companyTurns: {
      bind: "companyTurns"
    },
    whatsappNumber: {
      bind: "whatsappNumber"
    },
    appointmentModelSelect: {
      bind: "appointmentModelSelect",
    },
    birthdayModelSelect: {
      bind: "birthdayModelSelect",
    },
    taxForSaloon: {
      bind: 'taxForSaloon'
    },
    splitInServicePhase: {
      bind: 'splitInServicePhase'
    },
  },
  permissions: {
    Calendar: {
      bind: "calendarPermissions",
    },
  },
  licenseSettings: {
    viewBooking: {
      bind: "viewBooking"
    }
  },
  isLicenseBlocked: {
    bind: "isLicenseBlocked"
  },
  isOnlyWheelOfFortune: {
    bind: "isOnlyWheelOfFortune"
  },
};
</script>

<style>
.back-color-gradient {
  background-image: linear-gradient(#8a77e2, #50bfe1);
}

@media (max-width: 1263.98px) {
  .categories-calendar {
    height: calc(100vh - 64px);
  }
}

@media (min-width: 1264px) {
  .categories-calendar {
    height: 100vh;
  }
}
</style>
