<template>
  <v-container fluid class="px-0">
    <v-row align-content="center">
      <v-col class="d-flex align-center justify-center">
        <v-select
          filled
          :items="[5, 10, 15, 30]"
          v-model="calendarRoundToSetting"
          hide-details="auto"
          :menu-props="{offsetY: true}"
          :label="$translations.t('settings.calendarRoundTo.title')"
          @change="$settings.set('calendarRoundTo', $event)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row align-content="center">
      <v-col class="d-flex align-center">
        <v-switch
          v-model="modCalendar"
          :label="modCalendar.toString()"
          :false-value="'DYNAMIC'"
          :true-value="'PRO'"
        ></v-switch>  
      </v-col>
      <v-col class="d-flex align-center" v-if="false">
        <v-switch
          v-model="splitInServicePhase"
          label="Dividi in fasi"
          @change="$settings.set('splitInServicePhase', $event)"
        ></v-switch>  
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Calendar",
  data: function() {
    return {
      calendarRoundToSetting: undefined,
      modCalendar: 'PRO',
      splitInServicePhase: false
    }
  },
  settings: {
    calendarRoundTo: {
      bind: 'calendarRoundToSetting'
    },
    splitInServicePhase: {
      bind: 'splitInServicePhase'
    },
  },
  watch: {
    modCalendar(newVal){
      this.$emit('modCalendarChanged', newVal)
    }
  }
}
</script>

<style>

</style>