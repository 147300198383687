<template>
  <div class="d-flex flex-column">
    <v-overlay :value="loadingRunning">
      <slot name="loader" v-bind="{loading: loadingRunning}">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </slot>
    </v-overlay>
    <v-sheet height="fit-content" class="flex-grow-1 flex-shrink-0">
      <v-toolbar flat height="fit-content">
        <slot name="toolbar" v-bind="{
          calendar: $refs.calendar,
          prev: prev,
          next: next
        }">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Oggi</v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
          <slot name="toolbar-append" v-bind="{ 
            calendar: $refs.calendar, 
            prev: prev, 
            next: next 
          }"></slot>
        </slot>
      </v-toolbar>
    </v-sheet>
    <div class="flex-shrink-1 calendar-container">
      <v-calendar
        :locale="locale"
        ref="calendar"
        v-model="focus"
        color="primary"
        type="week"
        :events="events"
        :event-end="eventEnd"
        :event-start="eventStart"
        :event-name="eventName"
        :event-timed="eventTimed"
        :event-color="getEventColor"
        :event-text-color="getEventTextColor"
        :event-ripple="false"
        @change="fetchEvents"
        @mousedown:event="startDrag"
        @touchstart:event="startDrag"
        @mousedown:time="startTime($event)"
        @touchstart:time="touchStartTime($event)"
        @mousemove:time="mouseMove"
        @touchmove:time="touchMove"
        @contextmenu:event="openMenuDialog"
        @touchend:event="endTouch"
        @mouseup:time="endDrag"
        @mouseleave.native="cancelDrag"
      >
        <template v-slot:day-body="{ date, week }" v-if="showTimeline">
          <div
              v-if="!!dragEvent"
              class="v-event-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: eventY, '--background-color': getEventColor(dragEvent) }"
            >{{ eventTime }}</div>
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          ></div>
        </template>
        <template v-slot:event="{ event, timed, eventSummary }">
          <slot v-bind="{event, timed, eventSummary}">
            <div
              class="v-event-draggable"
              style="height: 100%"
              v-html="eventSummary()"
            ></div>
          </slot>
          <div 
            v-if="timed" 
            class="v-event-drag-bottom" 
            @mousedown.stop="extendBottom(event)"
            @touchstart.stop="extendBottom(event)"
          ></div>
        </template>
      </v-calendar>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import colorConstrast from "@/mixins/common/colorContrast"
import { cloneDeep } from "lodash";

export default {
  name: "WeekCalendar",
  mixins: [colorConstrast],
  components: { },
  data: () => ({
    localLoading: false,
    events: [],
    oldDragEvent: null,
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    prefilledCreateStart: undefined,
    prefilledCreateEnd: undefined,
    touchMoveListener: undefined,
    loadListener: undefined,
    ready: false,
  }),
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    eventsFetcher: {
      default: function() {
        return (start, end) => {
          return Promise.resolve([])
        }
      }
    },
    eventEnd: {
      type: String,
      default: 'end'
    },
    eventColor: { },
    eventTextColor: { },
    eventStart: {
      type: String,
      default: 'start'
    },
    eventName: {
      default: undefined
    },
    eventTimed: {
      default: undefined
    },
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    showTimeline: {
      type: Boolean,
      default: true
    },
    locale: {
      type: String,
      default: 'it'
    },
    roundTo: {
      type: Number,
      default: 15
    },
    focus: {
      default: new Date()
    },
  },
  mounted() {
    // prevents scrolling when dragging on modile
    this.touchMoveListener = function(e) {
      if (e.target.classList.contains('v-event-draggable') || e.target.classList.contains('v-event-drag-bottom')) {
        e.preventDefault();
      }
    }
    this.touchMoveListener = this.touchMoveListener.bind(this)
    this.loadListener = function() {
      if(document.getElementsByClassName('v-calendar-daily__scroll-area')[0]) {
        document.getElementsByClassName('v-calendar-daily__scroll-area')[0].addEventListener('touchmove', this.touchMoveListener)
      }
    }
    this.loadListener = this.loadListener.bind(this)
    window.addEventListener('load', this.loadListener)

    this.loadData();

    this.bus.$on('reload-events', () => {
      let date = new Date()
      if(this.focus != "") date = new Date(this.focus)
      this.fetchEvents({start: date, end: date})
    });

    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  beforeDestroy() {
    window.removeEventListener('load', this.loadListener)
    if(document.getElementsByClassName('v-calendar-daily__scroll-area')[0]) {
      document.getElementsByClassName('v-calendar-daily__scroll-area')[0].removeEventListener('touchmove', this.touchMoveListener)
    }
  },
  methods: {
    emit(event, ...params) {
      this.$emit(event, ...params)
      if (this.bus) {
        this.bus.$emit(event, ...params)
      }
    },
    loadData() {
      this.$refs.calendar.checkChange();
    },
    fetchEvents({ start, end }) {
      this.eventsFetcher(new Date(start.date), new Date(end.date)).then((events) => {
        this.events = events
      })
    },
    getEventColor(event) {
      if(!!this.eventColor && typeof this.eventColor == 'string') {
        return this.eventColor
      } else if(!!this.eventColor && typeof this.eventColor == 'function') {
        let color = this.eventColor(event)
        return color || '#050505'
      } else {
        return '#050505';
      }
    },
    getEventTextColor(event) {
      if(!this.eventTextColor) {
        let backgroundColor = this.getEventColor(event)
        return this.blackOrWhiteContrast(backgroundColor)
      } else {
        if(typeof this.eventTextColor == 'string') {
          return event[this.eventTextColor]
        } else if(this.eventTextColor == 'function') {
          return this.eventTextColor(event)
        } else {
          return '#050505';
        }
      }
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    openNewEventForm() {
      let prefilledEvent = {}
      prefilledEvent[this.eventStart] = this.prefilledCreateStart
      prefilledEvent[this.eventEnd] = this.prefilledCreateEnd
      this.emit('new-event', prefilledEvent)
    },
    openEditEventForm(event) {
      this.emit('edit-event', event)
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    roundTime(time, down = true) {
      const roundDownTime = this.roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.oldDragEvent = cloneDeep(event)
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent[this.eventStart];

        this.dragTime = mouse - start;
      } else {
        this.prefilledCreateStart = new Date(this.roundTime(mouse));
        this.prefilledCreateEnd = this.prefilledCreateStart;
        this.prefilledCreateEnd = new Date(this.roundTime(mouse) + 3600000);
        this.openNewEventForm()
      }
    },
    touchStartTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent[this.eventStart];

        this.dragTime = mouse - start;
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event[this.eventStart];
      this.extendOriginal = event[this.eventEnd];
    },
    touchMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent) {
        const start = this.dragEvent[this.eventStart];
        const end = this.dragEvent[this.eventEnd];
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent[this.eventStart] = newStart;
        this.dragEvent[this.eventEnd] = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent[this.eventStart] = min;
        this.createEvent[this.eventEnd] = max;
      }
    },
    endTouch() {
      this.endDrag()
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent[this.eventStart];
        const end = this.dragEvent[this.eventEnd];
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent[this.eventStart] = newStart;
        this.dragEvent[this.eventEnd] = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent[this.eventStart] = min;
        this.createEvent[this.eventEnd] = max;
      }
    },
    endDrag() {
      if(this.dragEvent) {
        this.emit('end-drag', this.dragEvent, this.oldDragEvent)
      } else {
        this.emit('end-drag', this.createEvent, this.oldDragEvent)
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent[this.eventEnd] = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    openMenuDialog({ event }) {
      this.emit('show-event', event)
    },
    getZeroPad (n) {
      return (parseInt(n, 10) >= 10 ? '' : '0') + n
    },
    getCurrentTime () {
      return this.calendar ? this.calendar.times.now.hour * 60 + this.calendar.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.calendar.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.calendar.updateTimes(), 60 * 1000)
    },
  },
  computed: {
    loadingRunning() {
      return this.loading || this.localLoading
    },
    calendar() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.calendar ? this.calendar.timeToY(this.calendar.times.now) + 'px' : '-10px'
    },
    eventY() {
      //const cal = this.$refs.calendar;
      if (!this.calendar && !this.isMounted) {
        return -1;
      }
      if (!this.dragEvent || this.dragEvent === null) {
        return -1;
      }
     
      let minutes = this.getZeroPad(new Date(this.dragEvent[this.eventStart]).getMinutes())
      let hours = this.getZeroPad(new Date(this.dragEvent[this.eventStart]).getHours())
      return this.calendar.timeToY(hours + ':' + minutes) + 'px';
    },
    eventTime() {
      const cal = this.$refs.calendar;
      if (!cal && !this.isMounted) {
        return -1;
      }
      if (!this.dragEvent || this.dragEvent === null) {
        return -1;
      }
           
      let minutes = this.getZeroPad(new Date(this.dragEvent[this.eventStart]).getMinutes())
      let hours = this.getZeroPad(new Date(this.dragEvent[this.eventStart]).getHours())
      return hours + ':' + minutes;
    },
  },
};
</script>

<style scoped lang="scss">

.calendar-container {
  overflow: auto;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.v-current-time {
  height: 2px;
  background-color: #8a77e2;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
  

     &.first::before {
      content: '';
      position: absolute;
      background-color: #8a77e2;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-top: -5px;
      margin-left: -6.5px;
    } 
  }

  .v-event-time {
  height: 2px;
  background-color: var(--background-color);
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

    &.first::before {
      content: '';
      position: absolute;
      background-color: var(--background-color);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-top: -5px;
      margin-left: -6.5px;
    } 
  }
</style>