<template>
  <v-container>
    <v-col cols="6">
      <v-row>
        <h3>Impostazioni Carpe Diem</h3>
      </v-row>
      <v-row class="d-flex align-center justify-space-between">
        <div class="pr-2">
          Attivo nelle prossime 
        </div>
        <v-select
          v-model="validForNextDays"
          :items="[{text: '24h', value: 1}, {text: '48h', value: 2}, {text: '72h', value: 3}]"
          item-text="text"
          item-value="value"
          dense
          rounded
          filled
          class="pl-2"
          style="width: 50%;"
          hide-details
        >
        </v-select>
      </v-row>
      <v-row class="d-flex align-center justify-space-between">
        <div class="pr-2">
          Attivo/Disattivo
        </div>
        <v-switch
          v-model="enabled"
          class="pl-2"
          inset
          color="secondary"
        >
        </v-switch>
      </v-row>
    </v-col>
    <v-col cols="12" style="overflow-y:auto;overflow-x: hidden; padding: 0%;">
      <v-row class="d-flex align-center justify-space-between ma-0">
        <h3>Orari</h3>
      </v-row>
      <div 
        style="height: 42vh; overflow-x: auto; 
          overflow-y: hidden; margin-top: 15px;
          display: flex; flex-direction: column;
          align-items: center;"
      >
        <div
          style="width: fit-content; gap: 15px;"
          class="d-flex align-center"
        >
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '27dvw' :
                $vuetify.breakpoint.sm ? '20dvw' :
                $vuetify.breakpoint.md ? '14dvw' : 
                $vuetify.breakpoint.xl ? '6dvw' : '10dvw'
              }"
            class="d-flex justify-center"
          >
            Da
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '27dvw' :
                $vuetify.breakpoint.sm ? '20dvw' :
                $vuetify.breakpoint.md ? '14dvw' : 
                $vuetify.breakpoint.xl ? '6dvw' : '10dvw'
              }"
            class="d-flex justify-center"
          >
            A
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '20dvw' :
                $vuetify.breakpoint.sm ? '13dvw' :
                $vuetify.breakpoint.md ? '10dvw' : 
                $vuetify.breakpoint.xl ? '5dvw' : '7dvw'
              }"
            class="d-flex justify-center"
          >
            Sconto
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '13dvw' :
                $vuetify.breakpoint.sm ? '9dvw' :
                $vuetify.breakpoint.md ? '7dvw' : 
                $vuetify.breakpoint.xl ? '3dvw' : '5dvw'
              }"
          >
            Attivo
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '13dvw' :
                $vuetify.breakpoint.sm ? '9dvw' :
                $vuetify.breakpoint.md ? '7dvw' : 
                $vuetify.breakpoint.xl ? '3dvw' : '5dvw'
              }"
          >
          </div>
        </div>
        <div
          style="width: fit-content; gap: 15px;"
          class="d-flex align-center" 
          v-for="(configuredHour, idx) in configuredHours" 
          :key="idx"
        >
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '27dvw' :
                $vuetify.breakpoint.sm ? '20dvw' :
                $vuetify.breakpoint.md ? '14dvw' : 
                $vuetify.breakpoint.xl ? '6dvw' : '10dvw'
              }"
            class="d-flex align-center"
          >
            <v-select
              v-model="configuredHour.from"
              :items="hoursItems"
              dense
              filled
              color="secondary"
              rounded
              hide-details
              @input="overlapCheck"
              :error="configuredHour.from > configuredHour.to"
            >
            </v-select>
          </div>
          <div
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '27dvw' :
                $vuetify.breakpoint.sm ? '20dvw' :
                $vuetify.breakpoint.md ? '14dvw' : 
                $vuetify.breakpoint.xl ? '6dvw' : '10dvw'
              }"
            class="d-flex align-center"
          >
            <v-select
              v-model="configuredHour.to"
              :items="hoursItems"
              dense
              filled
              color="secondary"
              rounded
              hide-details
              @input="overlapCheck"
              :error="configuredHour.from > configuredHour.to"
            >
            </v-select>
          </div>
          <div
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '20dvw' :
                $vuetify.breakpoint.sm ? '13dvw' :
                $vuetify.breakpoint.md ? '10dvw' : 
                $vuetify.breakpoint.xl ? '5dvw' : '7dvw'
              }"
            class="d-flex align-center"
          >
            <v-text-field
              v-model="configuredHour.discountPercentage"
              type="number"
              dense
              filled
              color="secondary"
              :error="configuredHour.discountPercentage < 0 || configuredHour.discountPercentage > 100"
              rounded
              hide-details
            ></v-text-field>
          </div>
          <div
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '13dvw' :
                $vuetify.breakpoint.sm ? '9dvw' :
                $vuetify.breakpoint.md ? '7dvw' : 
                $vuetify.breakpoint.xl ? '3dvw' : '5dvw'
              }"
            class="d-flex align-center"
          >
            <v-switch
              v-model="configuredHour.enabled"
              inset
              color="secondary"
              class="pa-0 ma-0"
              hide-details
            >
            </v-switch>
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs ? '13dvw' :
                $vuetify.breakpoint.sm ? '9dvw' :
                $vuetify.breakpoint.md ? '7dvw' : 
                $vuetify.breakpoint.xl ? '3dvw' : '5dvw'
              }"
          >
            <v-btn
              color="error"
              outlined
              @click="removeConfiguredHours(idx)"
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row class="d-flex justify-center" style="margin-top: 15px;">
          <v-btn
            color="secondary"
            outlined
            @click="addConfiguredHours"
            icon
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </div>
    </v-col>
    <v-col cols="12" class="mt-2">
      <v-row class="d-flex justify-center" style="width: 100%">
        <v-btn
          color="secondary"
          outlined
          @click="save"
          :disabled="overlapError || configuredHours.some(c => c.discountPercentage < 0 || c.discountPercentage > 100)"
        >
          Salva Orari
        </v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>

import bookingService from '@/services/booking/booking.service.js';

export default {
  name: "CarpeDiemSettings",
  components: {
  },
  data: function () {
    return {
      validForNextDays: 1,
      enabled: false,
      configuredHours: [],
      hoursItems: Array.from({length: ((17 * 4) + 1)}, (v, k) => {
        let hour = Math.floor(k / 4);
        hour += 6;
        let minutes = (k % 4) * 15;
        if (minutes === 0) minutes = '00';
        if (hour < 10) hour = '0' + hour;

        return {
          text: `${hour}:${minutes}`,
          value: "1970-01-01T" + hour + ":" + minutes + ":00"
        }
      })
    }
  },
  props: {

  },
  async mounted() {
    let res = await bookingService.getCarpeDiemSettings();

    this.validForNextDays = res.validForNextDays;
    this.enabled = res.enabled;
    this.configuredHours = res.configuredHours;
  },
  methods: {
    addConfiguredHours() {
      this.configuredHours.push({
        from: "1970-01-01T00:00:00",
        to: "1970-01-01T00:00:00",
        discountPercentage: 0,
        enabled: false
      });
    },
    removeConfiguredHours(idx) {
      this.configuredHours.splice(idx, 1);
    },
    save() {
      bookingService.saveCarpeDiemSettings({
        validForNextDays: this.validForNextDays,
        enabled: this.enabled,
        configuredHours: this.configuredHours
      }).then(res => {
        this.$delegates.snackbar('Impostazioni salvate con successo');
      });
    },
    overlapCheck() {
      let configuredHours = [...this.configuredHours];
      let sorted = configuredHours.filter().filter(el => !!el.enabled).sort((a, b) => {
        return a.from > b.from ? 1 : -1;
      })

      this.overlapError = false;
      for (let i = 0; i < sorted.length - 1; i++) {
        if (sorted[i].to > sorted[i + 1].from) {
          this.overlapError = true;
          this.$delegates.snackbar('Orari sovrapposti');
        }
      }
    },
  },
  computed: {
    
  },
};
</script>

<style>

</style>