<template>
  <div class="event-form-wrapper">
    <div>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-text-field
            v-model="title"
            label="Titolo"
            filled
            rounded
          ></v-text-field>
          <div>
            <OperatorsAutocomplete
              :filters="{ calendar : { name: calendarType } }"
              :disabled="!!allOperators"
              v-model="organizerId"
              :multiple="true"
              :return-object="false"
            ></OperatorsAutocomplete>
          </div>
          <v-switch
            class="mt-8"
            label="Tutti Gli Operatori"
            v-model="allOperators"
          ></v-switch>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-textarea
            filled
            v-model="description"
            label="Descrizione"
            hide-details="auto"
            rounded
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <DateTimePickerCircular
          v-model="startDate"
          :margin-top-time="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
          label="Inizio"
        ></DateTimePickerCircular>
        <DateTimePickerCircular
          :class="{
            'ml-6': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
            'mt-3': !!$vuetify.breakpoint.xs || !!$vuetify.breakpoint.sm
          }"
          v-model="endDate"
          :margin-top-time="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
          :min="startDate"
          label="Fine"
        ></DateTimePickerCircular>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import DateTimePicker from '@/components/common/DateTimePicker.vue';
import DateTimePickerCircular from '@/components/common/DateTimePickerCircular.vue';
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete';

export default {
  name: "EventForm",
  components: {
    OperatorsAutocomplete,
    DateTimePicker,
    DateTimePickerCircular
  },
  data: function() {
    let localOrganizerId = undefined
    if (typeof this.value.organizerId === 'number')
        localOrganizerId = [this.value.organizerId]
    else
        localOrganizerId = this.value.organizerId
    
    return {
      title: this.value.title,
      description: this.value.description,
      startDate: this.value.startDate,
      endDate: this.value.endDate,
      organizerId: localOrganizerId,
      allOperators: this.value.allOperators,
    }
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    value: {
      type: Object,
      default: function() {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    calendarType: { 
      type: Array,
      default: function() {
        return []
      }
    },
  },
  methods: {
  },
  watch: {
    value(newVal) {
      if (typeof newVal.organizerId === 'number')
        this.organizerId = [newVal.organizerId]
      else
        this.organizerId = newVal.organizerId

      this.title = newVal.title
      this.description = newVal.description
      this.startDate = newVal.startDate
      this.endDate = newVal.endDate
      this.allOperators = newVal.allOperators
    },
    allOperators(newVal) {
      if(!!newVal) {
        this.organizerId = undefined
      }
      if(newVal != this.value.allOperators) {
        this.$emit('input', this.localValue)
      }
    },
    title(newVal) {
      if(newVal != this.value.title) {
        this.$emit('input', this.localValue)
      }
    },
    description(newVal) {
      if(newVal != this.value.description) {
        this.$emit('input', this.localValue)
      }
    },
    startDate(newVal) {
      if(newVal != this.value.startDate) {
        this.$emit('input', this.localValue)
      }  
    },
    endDate(newVal) {
      if(newVal != this.value.endDate) {
        this.$emit('input', this.localValue)
      }
    },
    organizerId(newVal) {
      if(!!newVal) {
        this.allOperators = false
      }
      if(newVal != this.value.organizerId) {
        this.$emit('input', this.localValue)
      }
    },
  },
  computed: {
    localValue() {
      return {
        id: this.value.id,
        title: this.title,
        description: this.description,
        startDate: this.startDate,
        endDate: this.endDate,
        organizerId: this.organizerId,
        organizer: this.value.organizer,
        allOperators: this.allOperators
      }
    }
  }
}
</script>

<style>

</style>