<template>
  <v-form>
    <DateTimePickerCircular
      label="Duplica Data"
      :margin-top-time="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="localStartDate"
      filled
      class="align-center justify-center mt-2"
      style="width: 100%"
      @input="$emit('update:duplicate-date', $event)"
      date-picker-width="200px"
      v-if="localRuleType == 'once'"
    ></DateTimePickerCircular>
    <template v-else>
      <v-row class="mt-2">
        <v-col cols="6">
          <DateTimePickerCircular
            filled
            label="Inizio"
            :margin-top-time="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            v-model="localStartDate"
            @input="$emit('update:start-date', $event)"
          ></DateTimePickerCircular>
        </v-col>
        <v-col>
          <DatePicker
            filled
            label="Fine"
            v-model="localEndDate"
            @input="$emit('update:end-date', $event)"
          ></DatePicker>
        </v-col>
      </v-row>
    </template>
    <template v-if="localRuleType == 'weekly'">
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn-toggle
            v-model="localDaysOfWeek"
            rounded
            multiple
            @change="$emit('update:days-of-week', $event)"
          >
            <v-btn>Lun</v-btn>
            <v-btn>Mar</v-btn>
            <v-btn>Mer</v-btn>
            <v-btn>Gio</v-btn>
            <v-btn>Ven</v-btn>
            <v-btn>Sab</v-btn>
            <v-btn>Dom</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </template>
    <template v-if="localRuleType == 'monthly'">
      <v-row>
        <v-col class="d-flex justify-center">
          <v-autocomplete
            dense
            filled
            hide-details="auto"
            label="Giorni del mese"
            multiple
            clearable
            v-model="localDaysOfMonth"
            :items="daysOfMonthOptions"
            @input="$emit('update:days-of-month', $event)"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </template>
    <v-row class="mt-3 d-flex justify-center align-center">
      <v-radio-group
        v-model="localRuleType"
        row
        @change="$emit('update:rule-type', $event)"
      >
        <v-row>
          <v-col>
            <v-radio
              label="Una sola volta"
              value="once"
            ></v-radio>
          </v-col>
          <v-col>
            <v-radio
              label="Ripeti ogni giorno"
              value="daily"
            ></v-radio>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio
              label="Ripeti ogni settimana"
              value="weekly"
            ></v-radio>
          </v-col>
          <v-col>
            <v-radio
              label="Ripeti ogni mese"
              value="monthly"
            ></v-radio>
          </v-col>
        </v-row>
      </v-radio-group>
    </v-row>
    <template v-if="operatorSelector">
      <v-row class="mt-3 mr-3">
        <v-col class="d-flex justify-center">
          <v-switch
            class="mt-8"
            label="Tutti Gli Operatori"
            v-model="localOperatorChoice"
            @change="$emit('update:operator-choice', $event)"
          ></v-switch>
        </v-col>
      </v-row>
    </template>
    <v-row class="mt-2">
      <v-alert
        border="left"
        colored-border
        color="primary"
        elevation="2"
        style="width: 100%"
      >
        <div class="text-center">
          {{messages[localRuleType]}}
        </div>
      </v-alert>
    </v-row>
  </v-form>
</template>

<script>
import DateTimePicker from '@/components/common/DateTimePicker.vue';
import DateTimePickerCircular from '@/components/common/DateTimePickerCircular.vue';
import DatePicker from '@/components/common/DatePickerCommon.vue'

export default {
  name: 'DuplicateDateForm',
  components: {
    DatePicker,
    DateTimePickerCircular,
    DateTimePicker
  },
  data: function() {
    return {
      localDuplicateDate: this.duplicateDate,
      localRuleType: this.ruleType,
      messages: {
        once: "L'evento verrà duplicato nella data selezionata",
        daily: "L'evento verrà creato in ogni giorno compreso tra quelli inseriti",
        weekly: "L'evento verrà creato in ogni settimana nel giorno inserito all'interno delle date selezionate",
        monthly: "L'evento verrà creato in ogni mese nei giorni selezionati all'interno delle date selezionate"
      },
      localStartDate: this.startDate,
      localEndDate: this.endDate,
      localDaysOfWeek: this.daysOfWeek,
      daysOfMonthOptions: [...Array(32).keys()].filter(el => el != 0).map(el => ({text: el, value: el})),
      localDaysOfMonth: this.daysOfMonth,
      localOperatorChoice: this.operatorChoice,
    }
  },
  props: {
    duplicateDate: { },
    startDate: { },
    endDate: { },
    operatorChoice: { },
    operatorSelector: {
      type: Boolean,
      default: false
    },
    ruleType: {
      type: String,
      default: 'once',
      validator(value) {
        return ['once', 'weekly', 'daily', 'monthly'].indexOf(value) != -1
      }
    },
    daysOfWeek: {
      type: Array,
      default: () => []
    },
    daysOfMonth: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    duplicateDate(newVal) {
      this.localStartDate = newVal
    },
    startDate(newVal) {
      this.localStartDate = newVal
    },
    ruleType(newVal) {
      this.localRuleType = newVal
    },
    operatorChoice(newVal) {
      this.localOperatorChoice = newVal
    },
    endDate(newVal) {
      this.localEndDate = newVal
    },
    daysOfWeek(newVal) {
      this.localDaysOfWeek = newVal
    },
    daysOfMonth(newVal) {
      this.localDaysOfMonth = newVal
    },
    
  },
  computed: {
  }
}
</script>

<style>

</style>