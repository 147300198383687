<template>
  <ResponsiveCardSelector
    :items="operators"
    card-max-height="75"
    card-min-height="75"
    card-max-width="75"
    card-min-width="75"
    :loading="localLoading || loading"
    :disabled="disabled"
    :return-object="returnObject"
    :item-color="colorize ? (operator) => { return operator.color } : undefined"
    v-model="localValue"
    @input="$emit('input', localValue)"
    @click="$emit('click', $event)"
  >
    <template v-slot:item="{item, cardMinHeight, textColor}">
      <div v-if="showOnlyNameOperator"
        class="pa-2 d-flex flex-column justify-center align-center"
        :style="{'min-height': cardMinHeight.indexOf('px') != -1 ? cardMinHeight : cardMinHeight + 'px'}"
      >
        <div class="text-center" :style="{
          color: colorize ? textColor : undefined,
          fontSize: '12px'
        }">
          {{item.firstname}}
        </div>
      </div>
      <div v-else
        class="pa-2 d-flex flex-column justify-center align-center"
        :style="{'min-height': cardMinHeight.indexOf('px') != -1 ? cardMinHeight : cardMinHeight + 'px'}"
      >
        <div class="text-center" :style="{
          color: colorize ? textColor : undefined,
          fontSize: '12px'
        }">
          {{item.firstname}} {{item.lastname}}
        </div>
      </div>
    </template>
  </ResponsiveCardSelector>
</template>

<script>
import OperatorAvatar from './OperatorAvatar.vue'
import ResponsiveCardSelector from './ResponsiveCardSelector.vue'
import operatorManagment from "@/services/operators/operators.service.js";
import Vue from 'vue'

export default {
  name: "OperatorSelector",
  components: { 
    ResponsiveCardSelector,
    OperatorAvatar
  },
  data: function() {
    return {
      operators: [ ],
      colorize: false,
      localLoading: false,
      localValue: this.value || this.multiple ? [] : undefined
    }
  },
  props: {
    value: {},
    bus: {
      type: Object,
      default: () => { new Vue() }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({ notInStateOperator:'disabled' })
    },
    showOnlyNameOperator: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.loadOperators()

    if(!!this.bus) {
      this.bus.$on('reload', () => {
        this.loadOperators()
      })
    }
  },
  methods: {
    loadOperators() {
      this.localLoading = true
      operatorManagment.cachedList({filters: this.filters}).then((operators) => {
        this.operators = operators
        this.localLoading = false
      })
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    },
    filters() {
      this.loadOperators()
    }
  },
  settings: {
    colorizeOperatorSelector: {
      bind: 'colorize'
    }
  }
}
</script>

<style>

</style>