<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col style="max-height: 70vh; overflow-y: auto" class="pr-5">
        <v-timeline align-top dense>
          <v-slide-x-reverse-transition
            group
            hide-on-leave
          >
            <v-timeline-item
              fill-dot
              small
              v-for="eventService in eventServices"
              :key="eventService.id"
            >
              <v-card
                color="secondary"
                :class="{
                  'editing': editEventService && eventService.id == editEventService.id
                }"
              >
                <v-card-title class="text-h6">
                  {{eventService.service.name}}
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    @click="handleDelete(eventService)"
                    v-if="!editEventService || eventService.id != editEventService.id"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn 
                    icon
                    @click="handleEdit(eventService)"
                    v-if="!editEventService || eventService.id != editEventService.id"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn 
                    icon
                    @click="handleCancelEdit"
                    v-else
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="white text--primary py-2">
                  <div class="text-body">
                    <span class="font-weight-thin">Durata:</span> {{humanReadableMinutes(eventService.service.minutes)}} <br>
                    <span class="font-weight-thin">Inizio alle:</span> {{dateToString('dd/mm/yyyy', eventService.startDate)}}, {{dateToTimeString(eventService.startDate)}}
                  </div>
                  <div class="d-flex">
                    <OperatorChip 
                      v-for="operator in eventService.operators" 
                      :key="operator.id" 
                      class="mt-1 mr-1" 
                      :value="operator"
                    ></OperatorChip>
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-slide-x-reverse-transition>
        </v-timeline>
      </v-col>
      <v-col cols="auto" class="pt-9">
        <v-expand-x-transition>
          <div v-if="editing">
            <CustomersAutocomplete
              v-model="eventGroup.customer"
              :loading="loading"
              :disabled="loadingUp || loadingDown"
              @input="handleUpdateCustomer"
              return-object
            ></CustomersAutocomplete>
            <v-row>
              <v-col justify="center" align="center">
                <v-btn 
                  icon 
                  large
                  @click="moveServiceUp"
                  :loading="loadingUp"
                  :disabled="loadingDown"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
              </v-col>
              <v-col justify="center" align="center">
                <v-btn 
                  icon 
                  large
                  @click="moveServiceDown"
                  :loading="loadingDown"
                  :disabled="loadingUp"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <OperatorsAutocomplete
                  return-object
                  multiple
                  :disabled="loadingUp || loadingDown"
                  :loading="loading"
                  style="width: 300px"
                  v-model="editEventService.operators"
                  @input="handleUpdateOperator"
                ></OperatorsAutocomplete>
              </v-col>
            </v-row>
          </div>
        </v-expand-x-transition>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{snackbarMessage}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import eventGroupService from '@/services/calendar/eventGroup.service.js'
import dateUtils from '@/mixins/common/dateUtils'
import OperatorChip from '@/components/common/OperatorChip.vue'
import CustomersAutocomplete from '@/components/common/CustomersAutocomplete.vue'
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete.vue'
import Vue from 'vue'

export default {
  name: "EventGroupShow",
  components: {
    OperatorChip,
    CustomersAutocomplete,
    OperatorsAutocomplete
  },
  mixins: [
    dateUtils
  ],
  data: function() {
    return {
      eventGroup: {},
      editEventService: undefined,
      customer: undefined,
      operators: [],
      loadingUp: false,
      loadingDown: false,
      loading: false,
      snackbar: false,
      snackbarMessage: '',
    }
  },
  props: {
    value: { },
    bus: {
      type: Object,
      default: () => (new Vue())
    }
  },
  mounted: function() {
    if(this.bus) {
      let self = this
      this.bus.$on('reload-event-group', () => {
        self.fetchEventGroup().then(() => {})
      })
    }

    this.fetchEventGroup().then(() => {})
  },
  methods: {
    fetchEventGroup() {
      return new Promise((resolve, reject) => {
        eventGroupService.getFromEvent(this.value.id).then((eventGroup) => {
          this.eventGroup = eventGroup
          resolve()
        })
      })
    },
    handleEdit(eventService) {
      this.editEventService = eventService
    },
    handleCancelEdit() {
      this.editEventService = undefined
    },
    handleUpdateCustomer() {
      this.loading = true
      eventGroupService.setCustomer({id: this.editEventService.eventId}, this.eventGroup.customer).then(response => {
        this.fetchEventGroup().then(() => {
          this.loading = false
        })
      }).catch((err) => {
        if(err == 'customer is not defined') {
          this.fetchEventGroup().then(() => {
            for(let i = 0; i < this.eventServices.length; i++) {
              if(this.eventServices[i].eventId == this.editEventService.eventId) {
                this.editEventService = this.eventServices[i]
                this.snackbarMessage = 'L \'operatore deve esistere'
                this.snackbar = true
                this.loading = false
                break
              }
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    handleUpdateOperator() {
      this.loading = true
      eventGroupService.setOperator({id: this.editEventService.eventId}, this.editEventService.operators).then(response => {
        this.fetchEventGroup().then(() => {
          this.loading = false
        })
      }).catch((err) => {
        if(err == 'operators is not defined') {
          this.fetchEventGroup().then(() => {
            for(let i = 0; i < this.eventServices.length; i++) {
              if(this.eventServices[i].eventId == this.editEventService.eventId) {
                this.editEventService = this.eventServices[i]
                this.snackbarMessage = 'L \'operatore deve esistere'
                this.snackbar = true
                this.loading = false
                break
              }
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    moveServiceUp() {
      this.loadingUp = true
      eventGroupService.moveServiceUp(this.editEventService).then(() => {
        this.$emit('move-up')
        this.fetchEventGroup().then(() => {
          this.loadingUp = false
        })
      }).catch((err) => {
        this.loadingUp = false
        if(err.message == 'events not sequent') {
          this.snackbarMessage = "Gli eventi non sono sequenziali e non possono essere invertiti"
        } else if(err.message == 'cannot move up the first') {
          this.snackbarMessage = "Impossibile spostare in alto il primo evento della lista"
        }
        this.snackbar = true
      })
    },
    moveServiceDown() {
      this.loadingDown = true
      eventGroupService.moveServiceDown(this.editEventService).then(() => {
        this.$emit('move-down')
        this.fetchEventGroup().then(() => {
          this.loadingDown = false
        })
      }).catch((err) => {
        this.loadingDown = false
        if(err.message == 'events not sequent') {
          this.snackbarMessage = "Gli eventi non sono sequenziali e non possono essere invertiti"
        } else if(err.message == 'cannot move down the last') {
          this.snackbarMessage = "Impossibile spostare in basso l'ultimo evento della lista"
        }
        this.snackbar = true
      })
    },
    handleDelete(eventService) {
      let confirmed = confirm('sei sicuro di voler eliminare il gruppo?')

      if(confirmed) {
        this.loading = true
        eventGroupService.delete(eventService).then(() => {
          this.$emit('delete')
          this.fetchEventGroup().then(() => {
            this.loading = false
          })
        })
      }
    }
  },
  computed: {
    eventServices() {
      let results = []

      if(!!this.eventGroup && !!this.eventGroup.events) {
        for(let i = 0; i < this.eventGroup.events.length; i++) {
          if(!!this.eventGroup.events[i].eventServices) {
            for(let k = 0; k < this.eventGroup.events[i].eventServices.length; k++) {
              results.push({
                ...this.eventGroup.events[i].eventServices[k],
                startDate: new Date(this.eventGroup.events[i]['startDate'])
              })
            }
          }
        }
      }
      return results
    },
    editing() {
      return !!this.editEventService
    }
  },
  watch: {
    value() {
      this.fetchEventGroup()
    }
  }
}
</script>

<style scoped>
.editing {
  width: 95%;
}

.animation-properties {
  transition: all 0.2s;
}
</style>