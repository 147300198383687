<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="4">
        <v-timeline dense style="height: 100%">
          <v-slide-x-reverse-transition
            group
            hide-on-leave
          >
            <v-timeline-item
              fill-dot
              small
              key="1"
            >
              <div class="text-h4">
                {{formattedTime(event.startDate)}}
              </div>
              <div class="text-body">
                {{formattedDate(event.startDate)}}
              </div>
            </v-timeline-item>
            <v-timeline-item
              fill-dot
              small
              key="2"
              :style="{
                'margin-top': marginTop
              }"
            >
              <div class="text-h4">
                {{formattedTime(event.endDate)}}
              </div>
              <div class="text-body">
                {{formattedDate(event.endDate)}}
              </div>
            </v-timeline-item>
          </v-slide-x-reverse-transition>
        </v-timeline>
      </v-col>
      <v-col cols="8" class="right-column">
        <v-container fluid class="px-0">
          <v-row class="pa-0">
            <v-col cols="3" class="pa-0 text-h6 font-weight-black">
              Titolo
            </v-col>
            <v-col cols="9" class="px-0 pt-1">
              {{event.title}}
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col cols="3" class="pa-0 text-h6 font-weight-black">
              Descrizione
            </v-col>
            <v-col cols="9" class="px-0 pt-1" style="white-space: break-spaces;">{{event.description}}</v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col cols="3" class="pa-0 text-h6 font-weight-black">
              Organizzatore
            </v-col>
            <v-col cols="9" class="px-0 pt-1">
              <v-chip v-if="event.organizer">{{event.organizer.firstname}} {{event.organizer.lastname}}</v-chip>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col cols="3" class="pa-0 text-h6 font-weight-black">
              Partecipanti
            </v-col>
            <v-col cols="9" class="px-0 pt-1">
              <v-chip 
                v-for="operator in event.invitees" 
                :key="operator.id" 
                class="mx-1" 
              >{{operator.firstname}} {{operator.lastname}}</v-chip>
              <v-btn 
                icon
                @click="editInvitees"
              >
                <v-icon>mdi-account-edit-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EventShow",
  data: function() {
    return {
      marginTop: undefined
    }
  },
  props: {
    event: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  methods: {
    formattedTime(date) {
      date = new Date(date)
      return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
    },
    formattedDate(date) {
      date = new Date(date)
      return date.toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    },
    editInvitees() {
      this.$emit('edit-invitees')
    }
  },
  watch: {
    event() {
      var self = this
      self.marginTop = "0px"
      this.$nextTick(() => {
        setTimeout(() => {
          self.marginTop = (document.getElementsByClassName('right-column')[0].offsetHeight - 200) + 'px'
        }, 200);
      })
    }
  }
}
</script>

<style>

</style>