import eventsHandler from "@/services/common/eventsHandler"
import eventGroupService from "@/services/calendar/eventGroup.service"
import calendarService from '@/services/calendar/calendar.service'
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";
import { arrayMoveMutable } from 'array-move';
import { findIndex, sortBy } from 'lodash'

class EventServiceForm extends eventsHandler {
  constructor() {
    super()

    this._eventServices = []
    this._eventGroup = {}
    this._mode = undefined
    this._pending = false
    this._loadingDelete = false
  }

  get pending() {
    return this._pending
  }

  get eventGroup() {
    return { ...this._eventGroup }
  }

  get eventServices() {
    return [...this._eventServices]
  }

  get mode() {
    return this._mode
  }

  initialize(mode, eventGroup) {
    if (!eventGroup) {
      throw new Error('event group not specified')
    } else if (!mode || !['create', 'update'].includes(mode)) {
      throw new Error('mode not specified or not valid')
    }

    this._mode = mode

    this._eventGroup = {}
    this._eventGroup.startDate = !!eventGroup.startDate ? new Date(eventGroup.startDate) : undefined

    if (this._mode == 'update') {
      if(!!eventGroup.customer.preferredOperatorColor)
        eventGroup.customer.preferredColorOperatorId = eventGroup.customer.preferredOperatorColor.map(a => a.id)
      if(!!eventGroup.customer.preferredOperatorCut)
        eventGroup.customer.preferredHairCutOperatorId = eventGroup.customer.preferredOperatorCut.map(a => a.id)
      if(!!eventGroup.customer.preferredOperatorCurler)
        eventGroup.customer.preferredHairCurlerOperatorId = eventGroup.customer.preferredOperatorCurler.map(a => a.id)

      this._eventGroup.color = !eventGroup.color ? undefined : eventGroup.color
      this._eventGroup.customer = eventGroup.customer
      this._eventGroup.notes = eventGroup.notes
      this._eventGroup.highPriority = eventGroup.highPriority
      this._eventGroup.status = eventGroup.status
      this._eventGroup.star = !eventGroup.star ? false : eventGroup.star
      this._eventGroup.id = eventGroup.id
      this._eventGroup.fromBooking = eventGroup.fromBooking
      this._eventGroup.carpeDiem = eventGroup.carpeDiem
    }

    this._eventServices = []
    if (this._mode == 'update') {
      for (let i = 0; i < eventGroup.eventsInGroup.length; i += 1) {
        let eventService = {
          ...eventGroup.eventsInGroup[i].eventServices[0],
          startDate: eventGroup.eventsInGroup[i].startDate,
          endDate: eventGroup.eventsInGroup[i].endDate,
          eventId: eventGroup.eventsInGroup[i].id,
          new: false,
        }

        if (!!eventGroup.eventsInGroup[i].eventServices[0]) {
          eventService.service = { ...eventGroup.eventsInGroup[i].eventServices[0].service }
          if (!!eventGroup.eventsInGroup[i].eventServices[0].phase) {
            eventService.phase = eventGroup.eventsInGroup[i].eventServices[0].phase
          }


          this._eventServices.push(eventService)
        }
        else
          console.log(i, eventGroup)


      }
    }
    this._eventServices = sortBy(this._eventServices, (el) => { return el.startDate })

    this._emitEventGroupChange()
    this._emitEventServiceChange()
  }

  setCustomer(customer) {
    if (!!customer) {
      customer.preferredColorOperatorId = customer.preferredOperatorColor.map(a => a.id)
      customer.preferredHairCutOperatorId = customer.preferredOperatorCut.map(a => a.id)
      customer.preferredHairCurlerOperatorId = customer.preferredOperatorCurler.map(a => a.id)
    }
    return new Promise((resolve, reject) => {
      if (this._mode == 'create') {
        this._eventGroup.customer = customer
        this._emitEventGroupChange().then(() => {
          resolve(this._eventGroup)
        }).catch((err) => {
          reject(err)
        })
      } else if (this._mode == 'update') {
        eventGroupService.setCustomerWithGroup(this._eventGroup, customer).then((results) => {
          this._eventGroup.customer = customer
          Promise.all([
            this._emitEventGroupChange(),
            this._emitReload()
          ]).then(() => {
            resolve(this._eventGroup)
          }).catch((err) => {
            reject(err)
          })
        })
      }
    })
  }

  addService(service, operators, phase) {
    return new Promise((resolve, reject) => {
      if (!service || !service.id || !service.minutes || !service.name) {
        reject(new Error("id, minutes or name not present on service"))
        return
      }

      if (!!operators && !Array.isArray(operators)) {
        operators = [operators]
      } else {
        operators = []
      }

      if (operators.length != 0 && operators.any(op => !op.id)) {
        reject(new Error("all operator must have an id"))
        return
      }

      const uniqId = this._getUniqId()

      if (this._mode == 'create') {
        this._eventServices.push({
          id: uniqId,
          service: service,
          phase: phase,
          operators: operators,
          eventId: undefined,
          new: true
        })

        this._checkIds()
        this._calculateDates()
        this._checkIfPending()
        this._emitEventServiceChange().then(() => {
          resolve(this._eventServices)
        }).catch((err) => {
          reject(err)
        })
      } else if (this._mode == 'update') {
        if (operators.length == 0) {
          this._eventServices.push({
            id: uniqId,
            service: service,
            phase: phase,
            operators: operators,
            eventId: undefined,
            new: true
          })

          this._checkIds()
          this._calculateDates()
          this._checkIfPending()
          this._emitEventServiceChange().then(() => {
            resolve(this._eventServices)
          }).catch((err) => {
            reject(err)
          })
        } else {
          if (this._eventServices.length > 0) {
            eventGroupService.createServiceToExistingGroup({
              id: this._randomEventId(),
              endDate: this._eventServices[this._eventServices.length - 1].endDate
            }, service, operators, phase).then(({ event: newEvent, eventService: newEventService }) => {
              this._eventServices.push({
                eventId: newEvent.id,
                id: newEventService.id,
                service: service,
                phase: phase,
                operators: operators,
                new: false
              })

              this._checkIds()
              this._calculateDates()
              this._checkIfPending()
              Promise.all([
                this._emitReload(),
                this._emitEventServiceChange()
              ]).then(() => {
                resolve(this._eventServices)
              }).catch((err) => {
                reject(err)
              })
            }).catch((error) => {
              reject(error)
            })
          } else {
            eventGroupService.addServiceToExistingGroup(this.eventGroup, service, operators, phase, this._eventGroup.startDate).then(({ event: newEvent, eventService: newEventService }) => {
              this._eventServices.push({
                eventId: newEvent.id,
                id: newEventService.id,
                service: service,
                phase: phase,
                operators: operators,
                new: false
              })

              this._checkIds()
              this._calculateDates()
              this._checkIfPending()
              Promise.all([
                this._emitReload(),
                this._emitEventServiceChange()
              ]).then(() => {
                resolve(this._eventServices)
              }).catch((err) => {
                reject(err)
              })
            }).catch((error) => {
              reject(error)
            })
          }
        }
      }
    })
  }

  addOperatorToService(eventService, operator) {
    return new Promise((resolve, reject) => {
      if (!eventService || !eventService.id) {
        reject(new Error('event service not specified'))
        return
      } else if (!operator || !operator.id) {
        reject(new Error('operator not specified'))
        return
      }

      if (this._mode == 'create') {
        for (let i = 0; i < this._eventServices.length; i += 1) {
          if (this._eventServices[i].id.toString() == eventService.id.toString() && !this._eventServices[i].operators.some(op => op.id.toString() == operator.id.toString())) {
            this._eventServices[i].operators.push(operator)
          }
        }

        this._checkIfPending()
        this._emitEventServiceChange().then(() => {
          resolve(this._eventServices)
        }).catch((err) => {
          reject(err)
        })
      } else if (this._mode == 'update') {
        for (let i = 0; i < this._eventServices.length; i += 1) {
          if (this._eventServices[i].id.toString() == eventService.id.toString() && !this._eventServices[i].operators.some(op => op.id.toString() == operator.id.toString())) {
            if (this._eventServices[i].new && this._eventServices.length == 1) {
              eventGroupService.addServiceToExistingGroup(this.eventGroup, this._eventServices[i].service, [operator], this._eventServices[i].phase, this.eventGroup.startDate).then(({ event: newEvent, eventService: newEventService }) => {
                this._eventServices = [{
                  eventId: newEvent.id,
                  id: newEventService.id,
                  service: this._eventServices[i].service,
                  phase: this._eventServices[i].phase,
                  operators: [operator],
                  new: false
                }]

                this._checkIds()
                this._calculateDates()
                this._checkIfPending()
                Promise.all([
                  this._emitReload(),
                  this._emitEventServiceChange()
                ]).then(() => {
                  resolve(this._eventServices)
                }).catch((err) => {
                  reject(err)
                })
              }).catch((error) => {
                reject(error)
              })
            } else if (this._eventServices[i].new) {
              eventGroupService.createServiceToExistingGroup({
                id: this._randomEventId(),
                endDate: i >= 1 ? this._eventServices[i - 1].endDate : undefined
              }, this._eventServices[i].service, [operator], this._eventServices[i].phase).then(({ event: newEvent, eventService: newEventService }) => {
                this._eventServices[i].new = false
                this._eventServices[i].eventId = newEvent.id
                this._eventServices[i].id = newEventService.id
                this._eventServices[i].operators = [operator]

                this._checkIds()
                this._calculateDates()
                this._checkIfPending()
                Promise.all([
                  this._emitReload(),
                  this._emitEventServiceChange()
                ]).then(() => {
                  resolve(this._eventServices)
                }).catch((err) => {
                  reject(err)
                })

              })
            } else {
              const newOperators = this._eventServices[i].operators
              if (newOperators.some(op => op.id.toString() == operator.id.toString())) {
                resolve(this._eventServices)
                return
              }
              newOperators.push(operator)

              eventGroupService.setOperator({
                id: this._eventServices[i].eventId,
                startDate: this._eventServices[i].startDate
              }, newOperators).then(() => {
                this._eventServices[i].operators = newOperators

                this._checkIfPending()
                Promise.all([
                  this._emitReload(),
                  this._emitEventServiceChange()
                ]).then(() => {
                  resolve(this._eventServices)
                }).catch((err) => {
                  reject(err)
                })
              }).catch((error) => {
                reject(error)
              })
            }
          }
        }
      }
    })
  }

  removeOperatorFromService(eventService, operator) {
    return new Promise((resolve, reject) => {
      if (!eventService || !eventService.id) {
        reject(new Error('event service not specified'))
        return
      } else if (!operator || !operator.id) {
        reject(new Error('operator not specified'))
        return
      }

      if (this._mode == 'create') {
        for (let i = 0; i < this._eventServices.length; i += 1) {
          if (this._eventServices[i].id.toString() == eventService.id.toString()) {
            this._eventServices[i].operators = this._eventServices[i].operators.filter(el => parseInt(el.id) != parseInt(operator.id))
          }
        }

        this._checkIfPending()
        this._emitEventServiceChange().then(() => {
          resolve(this._eventServices)
        }).catch((err) => {
          reject(err)
        })
      } else if (this._mode == 'update') {
        for (let i = 0; i < this._eventServices.length; i += 1) {
          if (this._eventServices[i].id.toString() == eventService.id.toString()) {
            if (this._eventServices[i].new) { // if it is new means that has no operators
              reject(new Error("no operators on event services"))
            } else {
              let newOperators = this._eventServices[i].operators
              newOperators = newOperators.filter(el => parseInt(el.id) != parseInt(operator.id))
              if (newOperators.length == 0) {
                reject(new Error('can\'t remove all operators on event service'))
                return
              }

              eventGroupService.setOperator({
                id: this._eventServices[i].eventId
              }, newOperators).then(() => {
                this._eventServices[i].operators = newOperators

                this._checkIfPending()
                Promise.all([
                  this._emitEventServiceChange(),
                  this._emitReload()
                ]).then(() => {
                  resolve(this._eventServices)
                }).catch((err) => {
                  reject(err)
                })
              }).catch((error) => {
                reject(error)
              })
            }
          }
        }
      }
    })
  }

  removeService(eventService, modCalendar = 'PRO', externalMode = undefined) {
    return new Promise(async (resolve, reject) => {
      if (!eventService || !eventService.id) {
        reject(new Error('event service not specified'))
        return
      }

      this._executeAsyncCallbacksOf('disable-delete', true)

      if (this._mode == 'create') {
        this._eventServices = this._eventServices.filter(es => es.id != eventService.id)

        this._calculateDates()
        this._checkIfPending()
        this._emitEventServiceChange().then(() => {
          this._executeAsyncCallbacksOf('disable-delete', false)
          resolve(this._eventServices)
        }).catch((err) => {
          reject(err)
        })
      } else if (this._mode == 'update' || externalMode == 'update') {
        for (let i = 0; i < this._eventServices.length; i += 1) {
          if (this._eventServices[i].id.toString() == eventService.id.toString()) {
            if (this._eventServices[i].new) {
              this._eventServices = this._eventServices.filter(es => es.id != eventService.id)

              this._calculateDates()
              this._checkIfPending()
              this._emitEventServiceChange().then(() => {
                this._executeAsyncCallbacksOf('disable-delete', false)
                resolve(this._eventServices)
              }).catch((err) => {
                reject(err)
              })
            } else {
              eventGroupService.delete(this._eventServices[i]).then(async () => {
                this._eventServices = this._eventServices.filter(es => es.id != eventService.id)
                if (modCalendar == 'PRO')
                  this._calculateDates()
                this._checkIfPending()
                let eventServices = this._eventServices.map((event) => {
                  return {
                    id: event.eventId,
                    startDate: event.startDate,
                    endDate: event.endDate,
                    createdAt: event.createdAt,
                  }
                })
                await calendarService.updateMultipleEvents(eventServices)
                /*for (const event of this._eventServices) {
                  try {
                    await calendarService.updateEvent({
                      id: event.eventId,
                      startDate: event.startDate,
                      endDate: event.endDate,
                      createdAt: event.createdAt,
                    })
                  } catch (error) {
                    reject(error)
                  }
                }*/
                Promise.all([
                  this._emitEventServiceChange(),
                  this._emitReload(),
                ]).then(() => {
                  this._executeAsyncCallbacksOf('disable-delete', false)
                  resolve(this._eventServices)
                }).catch((err) => {
                  reject(err)
                })
              }).catch((error) => {
                reject(error)
              })
            }
          }
        }
      }
    })
  }

  removeServiceExternal(eventService) {
    return new Promise(async (resolve, reject) => {
      if (!eventService || !eventService.id) {
        reject(new Error('event service not specified'))
        return
      }

      this._executeAsyncCallbacksOf('disable-delete', true)

      let eventServices = [eventService]

      eventGroupService.delete(eventService).then(async () => {        
        this._checkIfPending()
        let selectedEventServices = eventServices.map((event) => {
          return {
            id: event.eventId,
            startDate: event.startDate,
            endDate: event.endDate,
            createdAt: event.createdAt,
          }
        })
        await calendarService.updateMultipleEvents(selectedEventServices)
        Promise.all([
          this._emitEventServiceChange(),
          this._emitReload(),
        ]).then(() => {
          this._executeAsyncCallbacksOf('disable-delete', false)
          resolve(undefined)
        }).catch((err) => {
          reject(err)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  }


  removeAllServices() {
    return new Promise((resolve, reject) => {
      if (this._mode == 'update') {
        eventGroupService.deleteAllServices(this.eventGroup).then(() => {
          if (!this._eventGroup) this._eventGroup = {}
          if (!this._eventServices || this._eventServices.length == 0) resolve()

          this._eventGroup.startDate = new Date(this._eventServices[0].startDate)
          this._eventServices = []

          this._calculateDates()
          this._checkIfPending()
          Promise.all([
            this._emitEventServiceChange(),
            this._emitReload(),
          ]).then(() => {
            resolve([])
          }).catch((error) => {
            reject(error)
          })
        })
      } else if (this._mode == 'create') {
        this._eventServices = []

        this._checkIfPending()
        Promise.all([
          this._emitEventServiceChange(),
          this._emitReload(),
        ]).then(() => {
          resolve([])
        }).catch((error) => {
          reject(error)
        })
      }

    })
  }

  moveInPosition(eventService, newIndex) {
    return new Promise((resolve, reject) => {
      if (newIndex == undefined || newIndex == null) {
        reject(new Error('index not defined'))
        return
      } else if (!eventService && !eventService.id) {
        reject(new Error('event service is not defined'))
        return
      } else if (newIndex >= this._eventServices.length) {
        reject(new Error('index out of range'))
        return
      }

      if (this._mode == 'create') {
        const oldIndex = findIndex(this._eventServices, el => el.id.toString() == eventService.id.toString())
        if (oldIndex == -1) return
        arrayMoveMutable(this._eventServices, oldIndex, newIndex)

        this._calculateDates()
        this._checkIfPending()
        this._emitEventServiceChange().then(() => {
          resolve(this._eventServices)
        }).catch((err) => {
          reject(err)
        })
      } else if (this._mode == 'update') {
        const oldIndex = findIndex(this._eventServices, el => el.id.toString() == eventService.id.toString())
        if (oldIndex == -1) return
        if (this._eventServices[oldIndex].new) {
          arrayMoveMutable(this._eventServices, oldIndex, newIndex)

          this._calculateDates()
          this._checkIfPending()
          this._emitEventServiceChange().then(() => {
            resolve(this._eventServices)
          }).catch((err) => {
            reject(err)
          })
        } else {
          eventGroupService.moveServiceInPosition(eventService, newIndex).then(() => {
            arrayMoveMutable(this._eventServices, oldIndex, newIndex)

            this._calculateDates()
            this._checkIfPending()
            Promise.all([
              this._emitEventServiceChange(),
              this._emitReload(),
            ]).then(() => {
              resolve(this._eventServices)
            }).catch((err) => {
              reject(err)
            })
          }).catch((error) => {
            reject(error)
          })
        }
      }
    })
  }

  updateLocal(eventGroup) {
    return new Promise((resolve, reject) => {
      let eventServicesChanged = false

      for (const [key, value] of Object.entries(eventGroup)) {
        this._eventGroup[key] = value

        if (key == 'startDate') {
          for (let i = 0; i < this._eventServices.length; i += 1) {
            this._eventServices[i].startDate = undefined
            this._eventServices[i].endDate = undefined
          }

          this._calculateDates()
          eventServicesChanged = true
        }
      }

      let promises = [
        this._emitEventGroupChange(),
        this._emitReload()
      ]

      if (eventServicesChanged) {
        promises.push(this._emitEventServiceChange())
      }

      Promise.all(promises).then(() => {
        resolve(this._eventGroup)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  update(eventGroup) {
    return new Promise((resolve, reject) => {
      if (!eventGroup) {
        reject(new Error('event group is not specified'))
        return
      } else if (this._mode == 'create') {
        this.updateLocal(eventGroup)
      } else if (this._mode == 'update') {
        eventGroup.id = this._eventGroup.id

        eventGroupService.updateGroup(eventGroup).then((newGroup) => {
          this._eventGroup = newGroup
          Promise.all([
            this._emitEventGroupChange(),
            this._emitReload(),
          ]).then(() => {
            resolve(this._eventGroup)
          }).catch((err) => {
            reject(err)
          })

        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  lockOperators(eventService) {
    return new Promise((resolve, reject) => {
      if (this._mode == 'create') {
        for (let i = 0; i < this._eventServices.length; i += 1) {
          if (this._eventServices[i].id.toString() == eventService.id.toString()) {
            this._eventServices[i].operatorsLocked = true
            break
          }
        }

        this._emitEventServiceChange().then(() => {
          resolve(this._eventServices)
        }).catch((error) => {
          reject(error)
        })
      } else if (this._mode == 'update') {
        const index = findIndex(this._eventServices, el => el.id.toString() == eventService.id.toString())
        const isNew = this._eventServices[index].new
        if (isNew) {
          this._eventServices[index].operatorsLocked = true
        } else {
          eventGroupService.updateEventService({
            id: eventService.id,
            eventId: eventService.eventId,
            operatorsLocked: true
          }).then(() => {
            for (let i = 0; i < this._eventServices.length; i += 1) {
              if (this._eventServices[i].id.toString() == eventService.id.toString()) {
                this._eventServices[i].operatorsLocked = true
                break
              }
            }

            Promise.all([
              this._emitEventServiceChange(),
              this._emitReload()
            ]).then(() => {
              resolve(this._eventServices)
            }).catch((error) => {
              reject(error)
            })
          })
        }
      }
    })
  }

  unlockOperators(eventService) {
    return new Promise((resolve, reject) => {
      if (this._mode == 'create') {
        for (let i = 0; i < this._eventServices.length; i += 1) {
          if (this._eventServices[i].id.toString() == eventService.id.toString()) {
            this._eventServices[i].operatorsLocked = false
            break
          }
        }

        this._emitEventServiceChange().then(() => {
          resolve(this._eventServices)
        }).catch((error) => {
          reject(error)
        })
      } else if (this._mode == 'update') {
        const index = findIndex(this._eventServices, el => el.id.toString() == eventService.id.toString())
        const isNew = this._eventServices[index].new
        if (isNew) {
          this._eventServices[index].operatorsLocked = false
        } else {
          eventGroupService.updateEventService({
            id: eventService.id,
            eventId: eventService.eventId,
            operatorsLocked: false
          }).then(() => {
            for (let i = 0; i < this._eventServices.length; i += 1) {
              if (this._eventServices[i].id.toString() == eventService.id.toString()) {
                this._eventServices[i].operatorsLocked = false
                break
              }
            }

            Promise.all([
              this._emitEventServiceChange(),
              this._emitReload()
            ]).then(() => {
              resolve(this._eventServices)
            }).catch((error) => {
              reject(error)
            })
          })
        }
      }
    })
  }

  changeServicesDates(date) {
    return new Promise((resolve, reject) => {
      //controllare event services
      if (this._mode == 'update') {
        eventGroupService.changeServicesDate(this.eventGroup, date).then(() => {
          const diffStarDate = new Date(date) - new Date(this._eventServices[0].startDate)
          for (let i = 0; i < this._eventServices.length; i++) {
            this._eventServices[i].startDate = new Date(new Date(this._eventServices[i].startDate).getTime() + diffStarDate)
            this._eventServices[i].endDate = new Date(new Date(this._eventServices[i].endDate).getTime() + diffStarDate)
          }
          this._calculateDates()
          this._checkIfPending()
          Promise.all([
            this._emitEventServiceChange(),
            this._emitReload(),
          ]).then(() => {
            resolve(this._eventServices)
          }).catch((err) => {
            reject(err)
          })
        }).catch((error) => {
          reject(error)
        })
      } else if (this._mode == 'create') {
        reject(new Error('cannot use the function in create'))
      }
    })
  }

  save() {
    return new Promise(async (resolve, reject) => {
      if (this._mode == 'update') {
        reject(new Error('no need to save on update'))
        return
      } else if (this._mode == 'create') {
        if (this._eventServices.length != 0) {
          let cashDesk = await cashDeskService.getOpened()
          let cashDeskLogId = !!cashDesk ? !!cashDesk.cashDeskLog ? cashDesk.cashDeskLog.id : undefined : undefined
          calendarService.createAppointment({
            group: this._eventGroup,
            eventServices: this._eventServices,
            cashDeskLogId: cashDeskLogId
          }).then((results) => {
            calendarService.reloadDayCache({
              date: this._eventGroup.startDate
            }).then(() => {
              resolve(results)
            })
          }).catch((error) => {
            reject(error)
          })
        } else {
          reject(new Error('cannot save without event services'))
        }
      }
    })
  }

  _checkIds() {
    if (this._eventServices.length == 0) return
    let usedIds = this._eventServices.map(es => parseInt(es.id))
    let indexesToChange = []

    for (let i = 0; i < this._eventServices.length; i += 1) {
      const id = parseInt(this._eventServices[i].id)
      const allOccurrencesNumber = usedIds.map((e, i) => e == id ? i : '').filter(e => e !== '').length
      if (allOccurrencesNumber > 1 && this._eventServices[i].new) indexesToChange.push(i)
    }

    let firstFreeId = Math.max(usedIds) + 1

    for (let i = 0; i < indexesToChange.length; i += 1) {
      const indexToChange = indexesToChange[i]
      this._eventServices[indexToChange].id = firstFreeId
      firstFreeId += 1
    }
  }

  _calculateDates() {
    if (this._eventServices.length == 0) return

    let beginning
    if (!!this._eventServices[0].startDate) beginning = new Date(this._eventServices[0].startDate)

    for (let i = 1; i < this._eventServices.length; i += 1) {
      if (!!this._eventServices[i].startDate && (!beginning || new Date(this._eventServices[i].startDate) < beginning)) {
        beginning = new Date(this._eventServices[i].startDate)
      }
    }

    if (!beginning) beginning = this._eventGroup.startDate
    if (!beginning) throw new Error('cannot find a beggining for the date calculations')

    for (let i = 0; i < this._eventServices.length; i += 1) {
      let duration

      if (this._eventServices[i].new) {
        if (!!this._eventServices[i].phase) {
          duration = parseInt(this._eventServices[i].phase.minutes) * 60000
        } else {
          duration = parseInt(this._eventServices[i].service.minutes) * 60000
        }
      } else {
        duration = new Date(this._eventServices[i].endDate) - new Date(this._eventServices[i].startDate)
      }

      this._eventServices[i].startDate = beginning
      this._eventServices[i].endDate = new Date(beginning.getTime() + duration)
      beginning = this._eventServices[i].endDate
    }

    this._eventServices = sortBy(this._eventServices, (el) => { return el.startDate })
  }

  _checkIfPending() {
    let oldPending = this._pending
    for (let i = 0; i < this._eventServices.length; i += 1) {
      if (this._eventServices[i]['operators'].length == 0) {
        this._pending = true
        break
      }
    }

    if (oldPending != this._pending) {
      this._executeCallbacksOf('change-pending', {
        group: this._eventGroup,
        eventServices: this._eventServices,
        pending: this._pending
      })
    }
  }

  _getUniqId() {
    let max = 0
    for (let i = 0; i < this._eventServices.length; i++) {
      if (parseInt(this._eventServices[i].id) > max) {
        max = parseInt(this._eventServices[i].id)
      }
    }
    return max + 1
  }

  _randomEventId() {
    for (let i = 0; i < this._eventServices.length; i += 1) {
      if (!!this._eventServices[i].eventId) return this._eventServices[i].eventId
    }
  }

  _emitEventServiceChange() {
    return this._executeAsyncCallbacksOf('change-event-services', {
      group: { ...this._eventGroup },
      eventServices: [...this._eventServices],
      pending: this._pending
    })
  }

  _emitEventGroupChange() {
    return this._executeAsyncCallbacksOf('change-group', {
      group: { ...this._eventGroup },
      eventServices: [...this._eventServices],
      pending: this._pending
    })
  }

  _emitReload() {
    return this._executeAsyncCallbacksOf('reload', {
      group: { ...this._eventGroup },
      eventServices: [...this._eventServices],
      pending: this._pending
    })
  }
}

export default new EventServiceForm()